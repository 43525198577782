import { BranchDetailsComponent } from './branch-details/branch-details.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core'
import { AppRoutingModule } from './app-routing.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AppComponent } from './app.component';
import { CheckOutComponent } from './check-out/check-out.component';
import { MenuComponent } from './menu/menu.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuItemsListComponent } from './menu-items-list/menu-items-list.component';
import { TrackOrderComponent } from './track-order/track-order.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { IknLocalizedPipe } from './ikn-localized.pipe';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeAr from '@angular/common/locales/ar';
import {ToastrModule, ToastNoAnimationModule} from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ShareButtonsModule} from '@ngx-share/buttons';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { IknLoaderDirective } from './ikn-loader.directive';
import { CartComponent } from './cart/cart.component';
import { AreaComponent } from './area/area.component';
import { FilterPipe } from './filter.pipe';
import { IknTimePipe } from './ikn-time.pipe';
import { FooterComponent } from './footer/footer.component';
import { AgmCoreModule } from '@agm/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import '../icons';
import { IknCurrencyPipe } from './ikn-currency.pipe';
import { IknDatePipe } from './ikn-date.pipe';
import { IknSafeHtmlPipe } from './iken-safe-html.pipe';
import { OrderSubmittedComponent } from './order-submitted/order-submitted.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ConfirmAddressComponent } from './confirm-address/confirm-address.component';

import { TermsComponent } from './terms/terms.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BsDropdownModule } from 'ngx-bootstrap';
import { CategoryPipe } from './category.pipe';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { MapComponent } from './map/map.component';
import { SubstitutionComponent } from './substitution/substitution.component';
import { ExtraCategoryAlertComponent } from './extra-category-alert/extra-category-alert.component';
import { BaseCategoryCardMaskComponent } from './base-category-card-mask/base-category-card-mask.component';
import { BaseCategoryCardComponent } from './base-category-card/base-category-card.component';
import { BaseCategoryBigCardComponent } from './base-category-big-card/base-category-big-card.component';
import { environment } from 'src/environments/environment';
import { MenuItemCardComponent } from './menu-item-card/menu-item-card.component';
import { MenuItemWithControlsCardComponent } from './menu-item-with-controls-card/menu-item-with-controls-card.component';
import { MenuItemBigCardComponent } from './menu-item-big-card/menu-item-big-card.component';
import { MenuItemInlineCardComponent } from './menu-item-inline-card/menu-item-inline-card.component';
import { CartIconBasketComponent } from './cart-icon-basket/cart-icon-basket.component';
import { CartIconComponent } from './cart-icon/cart-icon.component';
import { HomeComponent } from './home/home.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeIntl } from 'ng-pick-datetime';
import { DefaultIntl } from './DatePickerProviders';
import { AreaSelectionGuard } from './AreaSelectionGuard';
import { UtilsService } from './utils.service';
import { PrivacyComponent } from './privacy/privacy.component';
import { MenuAlertComponent } from './menu-alert/menu-alert.component';
import { ChangeDeliveryTimesComponent } from './change-delivery-times/change-delivery-times.component';
import { BranchSelectorComponent } from './branch-selector/branch-selector.component';
import { AskForMembershipComponent } from './ask-for-membership/ask-for-membership.component';
import { OrderSuccessPopupComponent } from './order-success-popup/order-success-popup.component';
import { CountrySelectorComponent } from './country-selector/country-selector.component';
import { TruckInfoComponent } from './truck-info/truck-info.component';
import { TopHeaderComponent } from './top-header/top-header.component';
import { iknMinutesPipe } from './ikn-minutes.pipe';
import { ServiceAreaComponent } from './service-area/service-area.component';
import { DateTimeComponent } from './date-time/date-time.component';
import { ShareButtonsConfig } from '@ngx-share/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { LoginPartComponent } from './login-part/login-part.component';
import { PreCheckoutComponent } from './pre-checkout/pre-checkout.component';
import { AddressPartComponent } from './address-part/addres-part.component';
import { SelectLocationComponent } from './select-location/select-location.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import {AddressesComponent} from './addresses/addresses.component';

import {OrderDetailComponent} from './order-detail/order-detail.component';
import { CateringMenuComponent } from './catering-menu/catering-menu.component';
import { MakeReservationComponent } from './make-reservation/make-reservation.component';

registerLocaleData(localeAr, 'ar');

// @NgModule({
//   exports: [TranslateModule],
//   imports:[TranslateModule.forRoot({
//     loader: {
//       provide: TranslateLoader,
//         useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
//         deps: [HttpClient]
//     }
//   }),]
// })
// export class AppTranslateModule {}

@NgModule({

  declarations: [IknLocalizedPipe,IknCurrencyPipe, IknDatePipe, IknSafeHtmlPipe]
  ,exports:[IknLocalizedPipe,IknCurrencyPipe, IknDatePipe, IknSafeHtmlPipe]
})
export class SharedPipesModule {}// for example can be any other used module

@NgModule({
  declarations: [BaseCategoryCardMaskComponent],
  exports: [BaseCategoryCardMaskComponent],

  imports:[CommonModule,SharedPipesModule,AppRoutingModule,TranslateModule.forChild({
    loader: {
      provide: TranslateLoader,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient]
    }
  })],
  providers:[UtilsService]
})
export class BaseCategoryCardMaskModule {}// for example can be any other used module

@NgModule({
  declarations: [BaseCategoryBigCardComponent],
  exports: [BaseCategoryBigCardComponent],

  imports:[CommonModule,SharedPipesModule,AppRoutingModule,TranslateModule.forChild({
    loader: {
      provide: TranslateLoader,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient]
    }
  })],
  providers:[UtilsService]
})
export class BaseCategoryBigCardModule {}// for example can be any other used module

@NgModule({
  declarations: [BaseCategoryCardComponent],
  exports: [BaseCategoryCardComponent],

  imports:[CommonModule,SharedPipesModule,AppRoutingModule,TranslateModule.forChild({
    loader: {
      provide: TranslateLoader,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient]
    }
  })]
})
export class BaseCategoryCardModule {}// for example can be any other used module


@NgModule({
  declarations: [MenuItemWithControlsCardComponent],
  exports: [MenuItemWithControlsCardComponent],

  imports:[CommonModule,SharedPipesModule,AppRoutingModule,TranslateModule.forChild({
    loader: {
      provide: TranslateLoader,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient]
    }
  })]
})
export class MenuItemWithControlsCardModule {}// for example can be any other used module


@NgModule({
  declarations: [MenuItemBigCardComponent],
  exports: [MenuItemBigCardComponent],

  imports:[CommonModule,SharedPipesModule,AppRoutingModule,TranslateModule.forChild({
    loader: {
      provide: TranslateLoader,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient]
    }
  })]
})
export class MenuItemBigCardModule {}// for example can be any other used module

@NgModule({
  declarations: [MenuItemInlineCardComponent],
  exports: [MenuItemInlineCardComponent],

  imports:[CommonModule,SharedPipesModule,AppRoutingModule,TranslateModule.forChild({
    loader: {
      provide: TranslateLoader,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient]
    }
  })]
})
export class MenuItemInlineCardModule {}// for example can be any other used module

@NgModule({
  declarations: [MenuItemCardComponent],
  exports: [MenuItemCardComponent],

  imports:[CommonModule,SharedPipesModule,AppRoutingModule,TranslateModule.forChild({
    loader: {
      provide: TranslateLoader,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient]
    }
  })]
})
export class MenuItemCardModule {}// for example can be any other used module

@NgModule({
  declarations: [CartIconBasketComponent],
  exports: [CartIconBasketComponent],

  imports:[CommonModule,SharedPipesModule,AppRoutingModule,TranslateModule.forChild({
    loader: {
      provide: TranslateLoader,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient]
    }
  })]
})
export class CartIconBasketModule {}// for example can be any other used module


@NgModule({
  declarations: [CartIconComponent],
  exports: [CartIconComponent],

  imports:[CommonModule,SharedPipesModule,AppRoutingModule,TranslateModule.forChild({
    loader: {
      provide: TranslateLoader,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient]
    }
  })]
})
export class CartIconModule {}

const customConfig: ShareButtonsConfig = {
  autoSetMeta:true,
  include:['whatsapp', 'copy','email'],
  theme: 'circles-dark'
};

@NgModule({
  declarations: [
    AppComponent,
    CheckOutComponent,
    MenuComponent,
    CateringMenuComponent,
    MenuItemComponent,
    MenuItemsListComponent,
    ServiceAreaComponent,
    TrackOrderComponent,
    IknLoaderDirective,
    CartComponent,
    AreaComponent,
    BranchSelectorComponent,
    DateTimeComponent,
    FilterPipe,
    IknTimePipe,
    iknMinutesPipe,
    FooterComponent,
    LoginPartComponent,
    AddressPartComponent,
    PreCheckoutComponent,
    // IknDatePipe,
    OrderSubmittedComponent,
    AboutUsComponent,
    TruckInfoComponent,
    TopHeaderComponent,
    ConfirmAddressComponent,
    TermsComponent,
    PrivacyComponent,
    CategoryPipe,
    SubCategoryComponent,
    SelectLocationComponent,
    MapComponent,
    SubstitutionComponent,
    ExtraCategoryAlertComponent,
    HomeComponent,
    MenuAlertComponent,
    ChangeDeliveryTimesComponent,
    AskForMembershipComponent,
    OrderSuccessPopupComponent,
    CountrySelectorComponent,
    LoginComponent,
    RegisterComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    OrderHistoryComponent,
    AddressesComponent,
    OrderDetailComponent,
    BranchDetailsComponent,
    MakeReservationComponent
    // environment.use_category_mask ? BaseCategoryCardMaskComponent : BaseCategoryCardComponent,
    // environment.use_item_controls ? MenuItemWithControlsCardComponent : MenuItemCardComponent,
    // environment.use_basket_cart ? CartIconBasketComponent : CartIconComponent

  ],
  imports: [
    SharedPipesModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSmartModalModule.forRoot(),
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    ShareButtonsModule.withConfig(customConfig),
    SlickCarouselModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgScrollbarModule,
    environment.use_category_mask ? 
    BaseCategoryCardMaskModule : (environment.use_big_category ? BaseCategoryBigCardModule: BaseCategoryCardModule),

    environment.use_item_controls ? MenuItemWithControlsCardModule : (
      environment.use_inline_item_card ? MenuItemInlineCardModule :
      (environment.use_big_cards ? MenuItemBigCardModule :  MenuItemCardModule)),
    environment.use_basket_cart ? CartIconBasketModule : CartIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
          deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDYl4CtntDAa3ooqPPxetY3bxtCGtmul4k',
      libraries: ['places', 'geometry']
    }),
    
  ],
  providers: [
    AreaSelectionGuard,
    {provide: OwlDateTimeIntl, useClass: DefaultIntl},

  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, undefined, '.json?v=' + environment.app_version);
}
console.log( environment.use_item_controls ? 'MenuItemWithControlsCardModule' : (
  environment.use_inline_item_card ? 'MenuItemInlineCardModule' :
  (environment.use_big_cards ? 'MenuItemBigCardModule' :  'MenuItemCardModule')));