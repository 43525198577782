import { Component, Input, NgZone, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { UtilsService } from '../utils.service';
import { slideInUpOnEnterAnimation } from 'angular-animations';
import { Address, GeoLocationDto } from '../models';
import { CartService } from '../cart.service';

import * as _ from 'lodash';
import { MapsAPILoader } from '@agm/core';
@Component({
  selector: 'app-map',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.css'],
  animations: [slideInUpOnEnterAnimation()]
})
export class SelectLocationComponent implements OnInit {
  pickupIcon: any = {
    icon: {
      url: '../../assets/images/markers/map-marker2.svg',
      scaledSize: { width: 41, height: 41 }
    }
  };
  pickupAddress: Address;
  mapCenter: GeoLocationDto;
  mapZoom: 17;
  boundaries: any[];
  areaColor = "lightgreen";
  isGeocoding = false;
  constructor(private srvCart: CartService, private api: ApiService, public utils: UtilsService,
    private mapApi: MapsAPILoader,
    private NgZone: NgZone) {
    this.pickupAddress = _.cloneDeep(this.srvCart.getCart().orderInfo.pickupAddress);
    this.mapCenter = _.clone(this.pickupAddress.location);
    this.api.getGovernate().subscribe(d => {
      let areas = _.flatMap(d.items, d => d.areas);
      let selected = _.find(areas, a => a.id == this.pickupAddress.areaId);
      if (selected && selected.boundaries) {
        let boundaries = JSON.parse(selected.boundaries);
        if (boundaries && boundaries.length)
          this.boundaries = _.map(boundaries, b => {
            return {
              lat: b.latitude, lng: b.longitude
            };
          });
        this.mapApi.load().then(() => {
          let latlng = new google.maps.LatLng(this.pickupAddress.location.lat, this.pickupAddress.location.lng);
          let poly = new google.maps.Polygon();
          poly.setPaths(this.boundaries.map(c => new google.maps.LatLng(c.lat, c.lng)));

          this.areaColor = google.maps.geometry.poly.containsLocation(

            latlng,
            poly
          )
            ? "lightgreen"
            : "red";
        });
      }
    })
  }

  ngOnInit() {

  }
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.mapCenter = {
          lat: _.clone(position.coords.latitude),
          lng: _.clone(position.coords.longitude),
        }
        this.onCenterChanged(this.mapCenter);
        console.log(this.mapCenter);
        this.mapZoom = 17;
        
        this.decodeAddress();
      });
    }
  }
  onCenterChanged(center) {
    
    this.pickupAddress.location = {
      lat: center.lat,
      lng: center.lng
    }
    console.log(this.mapCenter);

  }
  onMapIdle() {
    this.decodeAddress();
  }
  decodeAddress() {
    this.mapApi.load().then((api) => {
      this.isGeocoding = true;
      let geocoder = new google.maps.Geocoder;
      let latlng = { lat: this.pickupAddress.location.lat, lng: this.pickupAddress.location.lng };

      geocoder.geocode({ 'location': latlng }, results => {
        this.NgZone.run(d => {
          this.utils.setAddressFromGeocoder(results, this.pickupAddress);

          this.isGeocoding = false;
          if (this.boundaries) {

            let latlng = new google.maps.LatLng(this.pickupAddress.location.lat, this.pickupAddress.location.lng);
            let poly = new google.maps.Polygon();
            poly.setPaths(this.boundaries.map(c => new google.maps.LatLng(c.lat, c.lng)));
            this.areaColor = google.maps.geometry.poly.containsLocation(

              latlng,
              poly
            )
              ? "lightgreen"
              : "red";

          }
        });
      });
    });
  }

  save() {
    this.srvCart.setAddress(this.pickupAddress);
    this.utils.goBack();
  }
}
