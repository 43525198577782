import { Component, OnInit } from '@angular/core';
import { ApiResult, Area, DeliveryTimeWrapper, governate, LocalizedField, TruckInfo } from '../models';
import { ApiService } from '../api.service';
import { UtilsService } from '../utils.service';
import { CartService } from '../cart.service';
import { Cart } from '../cart';
import * as _ from 'lodash';
import * as moment from 'moment';
import { forInRight } from 'lodash';
import { ActivatedRoute, Router, RouterState } from '@angular/router';

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.css']
})
export class DateTimeComponent implements OnInit {
  truck: TruckInfo;
  cart: Cart;
  selectedDate: number
  selectedTime: number
  selectedSlotId: number
  selectedSlot: DeliveryTimeWrapper;
  dates = [];
  slots: DeliveryTimeWrapper[]
  isLoading: boolean = false
  isLoadingSlots: boolean = false;
  continue: any[];
  minAvailableTime: number
  minAvailableDate: Date
  dateOnly: boolean = false;
  forCatering: boolean = false;
  constructor(private api: ApiService, private srvCart: CartService, public utils: UtilsService, private router: Router, private route: ActivatedRoute) {
    this.isLoading = true;
    this.getDeliveryTimes(new Date());
    this.route.params.subscribe(d => {
      if (d.continue)
        this.continue = [d.continue];
    })
    this.route.data.subscribe(d => {
      this.forCatering = d.forCatering;
    });
  }

  ngOnInit() {

  }

  getDeliveryTimes(inRespectTo) {
    this.isLoadingSlots = true;
    let that = this;
    inRespectTo = inRespectTo || new Date();
    inRespectTo = moment(inRespectTo).startOf('day').toDate();
    this.api.viewTruck().subscribe(d => {
      this.dateOnly = d.truck.useDateOnlyForPreOrder;
      this.api.getDeliveryTimes(inRespectTo || new Date()).subscribe(times => {
        if (!this.minAvailableTime) {
          this.minAvailableTime = times.asSoonAsFrom;
          this.minAvailableDate = moment(this.minAvailableTime).startOf('day').toDate();

          let availableDate = moment(this.minAvailableDate);
          for (let i = 0; i < 10; i++) {
            this.dates.push(availableDate.toDate().getTime());
            availableDate = availableDate.add(1, 'day');
          }
        }
        let selectedTime;
        if (this.selectedSlotId) {
          selectedTime = _.find(times.laterOptions, d => d.deliveryTime.id == this.selectedSlotId);
        } else {
          selectedTime = _.find(times.laterOptions, d => d.isAvailable);
        }
        if (selectedTime && selectedTime.isAvailable) {
          this.selectedDate = moment(selectedTime.from).startOf('day').toDate().getTime();

          this.selectedTime = selectedTime.from;
          this.selectedSlot = selectedTime;
          this.selectedSlotId = selectedTime.deliveryTime.id;
        }
        //if(!this.truckDeliveryTimeId){
        if (!this.selectedTime) {
          this.selectedTime = times.isAvailable ? times.asSoonAsFrom : null;
        }
        if (this.selectedTime && !this.selectedSlot)
          this.selectedSlot = { from: this.selectedTime, isAvailable: times.isAvailable, isPreOrder: times.isPreOrder, to: null, deliveryTime: null };

        //}
        this.slots = times.laterOptions;
        this.isLoadingSlots = false;
        this.isLoading = false;
      });
    });
  }

  setDate(date) {
    this.selectedDate = date;
    this.selectedSlot = null;
    this.selectedSlotId = null;
    this.selectedTime = null;
    this.getDeliveryTimes(this.selectedDate);
  }

  setSlot() {
    this.selectedSlot = _.find(this.slots, d => d.deliveryTime.id == this.selectedSlotId);
    if (this.selectedSlot && this.selectedSlot.isAvailable) {
      this.selectedTime = this.selectedSlot.from;
    }
  }

  save() {
    let cart = this.srvCart.getCart()
    if (this.slots && this.slots.length) {
      let time = _.find(this.slots, f => f.deliveryTime.id == this.selectedSlotId)

      if (this.forCatering) {
        this.srvCart.cateringTime = time.from;
        this.srvCart.cateringTimeTo = time.to


      } else {
        cart.orderInfo.truckDeliveryTimeId = this.selectedSlotId;
        cart.orderInfo.pickupTime = time.from;
        cart.orderInfo.pickupTimeTo = time.to;
      }
    } else {
      if (this.forCatering) {
        this.srvCart.cateringTime = this.minAvailableTime;
        this.srvCart.cateringTimeTo = null;


      } else {
        //No Slots, we just have date
        cart.orderInfo.truckDeliveryTimeId = null;
        cart.orderInfo.pickupTime = this.minAvailableTime;
        cart.orderInfo.pickupTimeTo = null;
      }
    }
    this.srvCart.saveCart();
    if (this.continue)
      this.router.navigate(this.continue);
    else
      this.utils.goBack();
  }
}
