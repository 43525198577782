import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ApiService } from '../api.service';
import { CartService } from '../cart.service';
import * as _ from 'lodash';
import { GeoLocationDto, LocalizedField } from '../models';

@Component({
  selector: 'app-order-success-popup',
  templateUrl: './order-success-popup.component.html',
  styleUrls: ['./order-success-popup.component.css']
})
export class OrderSuccessPopupComponent implements OnInit {

  @Output()
  trackOrder = new EventEmitter();
  order: any;
  dateOnly: boolean = false;
  isLoading:boolean = false;
  address: LocalizedField = {ar:'',en:''};
  location: GeoLocationDto = {lat:0,lng:0};
  nextCallback:()=>void
  constructor(public modal:NgxSmartModalService, private api: ApiService, private srvCart: CartService) { }

  showOrderSuccessPopup(cb?:()=>void, orderId?, orderType?) {
    this.modal.getModal('mOrderSuccess').open();
    this.nextCallback = cb;
    this.isLoading = true;
    this.checkOrderType(orderId, orderType).subscribe(i=>{
      this.dateOnly = i.truck.useDateOnlyForPreOrder;
      this.order = _.cloneDeep(i);
      this.address = !orderType || orderType == 'Order' ? {en:this.order.truckLocation.addressLine1,ar:  this.order.truckLocation.addressLine2} : {ar:'',en:''};
      this.location = this.order.truckLocation && this.order.truckLocation.location;
      console.log(this.order);
      if(this.order.paymentMethod != 'cashOnDelivery' && this.order.status != 'PendingPayment' && this.order.status != 'PaymentRejected')
        this.srvCart.emptyCart();
      this.isLoading = false;
    });
  }

  checkOrderType(orderId, orderType){
    if(!orderType)
      orderType = 'Order';
    if(orderType == 'GroupedOrder')
      return this.api.viewGroupedOrder(Number(orderId));
    else
      return this.api.viewOrder(Number(orderId));
  }

  ngOnInit() {
  }

  onDismiss(){
    this.modal.getModal('mOrderSuccess').close();
    this.trackOrder.emit(this.order && this.order.orderNo);
    if(this.nextCallback)
      this.nextCallback();
    this.nextCallback = undefined;
  }
}
