import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { MenuItem,round, MenuExtraItem, CartItem, CartExtraItem, TruckInfo, MenuExtraCategory, AppEventType, SBOptions } from '../models';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { getLocaleExtraDayPeriods } from '@angular/common';
import * as _ from 'lodash';
import { CartService } from '../cart.service';
import { UtilsService } from '../utils.service';
import { Cart } from '../cart';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { slideInUpOnEnterAnimation } from 'angular-animations';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css'],
  animations:[slideInUpOnEnterAnimation({delay:1000})]
})
export class MenuItemComponent implements OnInit {
  carts:Cart
  existCartItem:CartItem;
  isLoading: boolean;
  isOutOfStock:boolean = false;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    dots: true,
    arrows: true,
    infinite: true,
  };
  truck:TruckInfo;
  sbOptions:SBOptions;
  askForFemaleService: boolean;
  oldQty: number;
  env = environment;
  constructor(private api:ApiService,
    private cart:CartService, private route:ActivatedRoute,
    private router:Router, public utils:UtilsService, private toaster:ToastrService,
    private trans:TranslateService) {
    this.carts= cart.getCart();
   }
   pageUrl: string;
   get selectedExtras():Array<MenuExtraItem>{
     if(!this.item)
      return[];
    return _(this.item.extras)
      .map(c=> c.extraItems.map(ei => { ei.catId = c.extraCategory.id; return ei; }))
      .concat()
      .flatten()
      .filter(c=>c.qty > 0)
      .value()
    }
   get calculatedPrice():number
   {
    if(!this.item)
      return;
    let sumExtras = _.sumBy(this.selectedExtras,d=>d.price * d.qty);
    return round((this.item.itemInfo.price + sumExtras || 0));
   }
   get subTotal():number
   {
     if(!this.item)
      return;
    let sumExtras = _.sumBy(this.selectedExtras,d=>d.price * d.qty);
    return round(this.item.itemInfo.price * this.qty + (sumExtras || 0));
   }
  item:MenuItem
  qty: number = 1
   notes:string = ""
  ngOnInit() {
    this.isLoading = true;
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        let id = params.get('id');
        this.pageUrl = this.utils.getUrl('item/' + id );
        this.api.trackEvent(AppEventType.ViewItem, Number(id));
        return this.api.viewItem(Number(id))
      })
    ).subscribe(i=>{
      this.item = _.cloneDeep(i);
      this.checkOutOfStock();
      this.checkCartItemExists();
      this.utils.fbTrack('PageView');
      this.api.viewTruck().subscribe(truck=>{
        this.isLoading = false;
        this.truck = truck.truck;
        if(!this.utils.isNavigationAllowed(this.truck))
        {
          this.router.navigate(['/home']);
          return;
        }
        this.isOutOfStock = this.checkOutOfStock();

      })
    });
    this.api.viewSBOptions().subscribe(d=>{
      this.sbOptions = d.simpleBrokerOptions;
    })
  }
  extraDisabled(ecat,extra){
    return !extra.isSelected &&
    _.filter(ecat.extraItems, ei=>ei.isSelected).length >= ecat.extraCategory.maxQty


  }
  clearSelectedExtras(ecat){
    _.forEach(ecat.extraItems, ei => {
      ei.isSelected = false,
      ei.qty = 0
    });
  }
  hasSelectedExtras(ecat){
    return _.some(ecat.extraItems, ei=>ei.isSelected);
  }
  addQty(){
    if(!this.item)
      return;
    let oldQty = 0;
    if(this.existCartItem)
      oldQty = this.existCartItem.qty;
    if(!this.cart.validateItemToAdd(this.item,oldQty, this.qty + 1 - oldQty, (this.qty + 1 - oldQty) * this.calculatedPrice))
      return;
    this.qty=this.qty+1
  }
  addToCart(checkout: boolean = false){
    if(!this.item)
      return;
    let oldQty = 0;
    if(this.existCartItem)
      oldQty = this.existCartItem.qty;
    if(!this.cart.validateItemToAdd(this.item,oldQty, this.qty - oldQty, (this.qty - oldQty) * this.calculatedPrice, true))
      return;

    if(this.existCartItem){
      this.existCartItem.qty = this.qty;
      this.existCartItem.askForFemaleService = this.askForFemaleService;
      if(this.selectedExtras.length)
        this.existCartItem.extras = _.map(this.selectedExtras, ei=>{
            let res =new CartExtraItem();
              res.$extraItem = ei;
            return res;
          });
      this.cart.saveCart();
    }else{
      this.cart.addItem(_.assignIn(new CartItem(),{
        $item:this.item.itemInfo,
        askForFemaleService:this.askForFemaleService,
        extras: _.map(this.selectedExtras, ei=>{
          let res =new CartExtraItem();
            res.$extraItem = ei;
          return res;
        }),
        qty: this.qty,
        notes: this.notes,
      }));
    }
    this.utils.fbTrack('AddToCart');
    this.api.trackEvent(AppEventType.AddToCart, this.item.itemInfo.id, {qty: this.qty});
    //this.router.navigate(['/categories/' + this.item.catId + '/items']);
    if(checkout)
      this.router.navigate(['/cart']);
    else
      this.utils.goBack();
  }
  checkOutOfStock(){
    return this.cart.isOutOfStock(this.item);
  }

  generateShareUrl(){
    return location.href;
  }

  checkCartItemExists(){
    // if(this.item.extras.length)
    //   return;
    this.oldQty = this.item.qty;
    let found = _.find(this.carts.cartItems,c => c.$item.id == this.item.itemInfo.id);
    if(found){ //&& !this.item.extras.length
      this.existCartItem = found;
      this.qty = found.qty;
      this.askForFemaleService = found.askForFemaleService;
    }

    if(this.item.extras.length){ // Get existing qtys for extras and increase or decrease
      let allExtras = _(this.item.extras).map(c=>c.extraItems).concat().flatten().value();
      _.forEach(allExtras, function(extra){
        if(found)
          var foundExtra = _.find(found.extras, function(cartExtraItem) {
            return cartExtraItem.$extraItem.id == extra.id
          });
        extra.qty = !found || !foundExtra ? 0 : foundExtra.$extraItem.qty;
      });
    }
  }

  checkExtraOutOfStock(extra){
    return extra.isStocked && (extra.stockQty == 0 || !extra.stockQty) && !extra.saleOutStocked
  }

  addExtraItem(extra: MenuExtraItem, cat: MenuExtraCategory){
    let totalExtrasQty = _.sumBy(cat.extraItems, ei=>ei.qty + (ei.id == extra.id ? 1 : 0));

    if(!this.cart.validateExtraItemToAdd(extra, extra.qty, 1, totalExtrasQty, this.qty, cat.extraCategory))
      return;

    extra.qty+=1;
  }

  removeExtraItem(extra){
    extra.qty-=1;
    if(extra.qty < 0)
      extra.qty = 0;
    // if(sumOfExtraCatQty < ((ec.extraCategory.minQty || 0) * this.qty)){ // check if it less than min qty
    //   this.trans.get('MIN_Qty_EXTRA', {ec:ec.extraCategory}).subscribe(msg=>{
    //     this.toaster.error(msg);
    //   });
    //   errorFound = true;
    //   return false;
    // }
  }

  sliderInit({event, slick}){
    let lis = slick.$dots.find('li');
    _.each(lis, (li, index)=>{
      $(li).css({width:'auto', height:'auto'}).html(`<div style='border-radius:5px;margin:4px;width:50px;height:60px;background:lightgrey url("${this.item.itemInfo.itemPics[index].pic}") no-repeat center; background-size:cover' ></div>`);
    });
  }

}
