import { Component, OnInit, OnDestroy } from '@angular/core';
import { CartService } from '../cart.service';
import { Cart } from '../cart';
import { CartItem, CartExtraItem, TruckViewDto, TruckInfo, AppEventType } from '../models';
import { UtilsService } from '../utils.service';
import * as _ from 'lodash'
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { fadeInOnEnterAnimation, lightSpeedInAnimation, lightSpeedInOnEnterAnimation, slideInUpOnEnterAnimation, slideOutDownOnLeaveAnimation } from 'angular-animations';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
  animations: [slideInUpOnEnterAnimation({ delay: 10 }),slideOutDownOnLeaveAnimation({ delay: 2 }), fadeInOnEnterAnimation(), lightSpeedInOnEnterAnimation()]
})
export class CartComponent implements OnInit, OnDestroy {

  flag = true;
  isLoading: boolean = false;
  isLoadingC
  cart: Cart
  truck: TruckInfo
  isPickupServiceSelected: boolean = false;
  submitForm: FormGroup;
  submitted: boolean = false;
  serviceType: string;
  promoCode: string;
  isValidatingPromoCode: boolean = false;
  cateringIniated: boolean = false;
  cateringTime: number;
  cateringTimeTo: number;
  constructor(private modal: NgxSmartModalService,
    public srvCart: CartService,
    private formBuilder: FormBuilder,
    private api: ApiService, public utils: UtilsService,
    private toaster: ToastrService,
    private route: ActivatedRoute,
    private router: Router,

    private trans: TranslateService) {
    this.submitForm = this.formBuilder.group({
      'promoCode': ''
    });
    this.cart = srvCart.getCart();
    this.cateringTime = this.cart.orderInfo.pickupTime || this.srvCart.cateringTime;
    this.cateringTimeTo = this.cart.orderInfo.pickupTimeTo  || this.srvCart.cateringTime;
    if(this.srvCart.getServiceType() == 'catering'){
      this.srvCart.cateringTime =  this.cateringTime;
      this.srvCart.cateringTimeTo = this.cateringTimeTo;
    }
    this.cateringIniated = this.srvCart.isCateringInitiated;
    this.route.queryParams.subscribe(params => {
      let cartParam = params['c'];
      if (cartParam) {
        let strCartInfo = decodeURIComponent(cartParam);
        strCartInfo = atob(strCartInfo);
        this.loadFromSharedCart(JSON.parse(strCartInfo));
      } else {
        this.isLoading = true;
        this.api.viewTruck().subscribe(truck => {
          this.truck = truck.truck;
          this.promoCode = this.cart.orderInfo.promoCode;
          if (this.promoCode) {
            this.srvCart.setPromoCode('', 0);
            //recalculate the promo code every time user enters this screen
            this.redeemOrDeletePromoCode();
          }
          this.isPickupServiceSelected = this.cart.$truck.services.includes('pickup');
          if (!this.isPickupServiceSelected) {
            this.serviceType = 'delivery';
            this.setServiceType(this.serviceType);
          }
          this.isLoading = false;
        })
      }
    });
  }

  redeemOrDeletePromoCode() {
    if (this.cart.orderInfo.promoCode) {
      this.srvCart.setPromoCode('', 0);
      this.promoCode = '';
      return;
    }

    this.isValidatingPromoCode = true;
    let that = this;
    var orderModel = this.api._orderFromCart(this.cart);
    var cartItems = orderModel.cartItems;
    this.api.redeemPromoCode({
      promoCode: this.promoCode,
      paymentMethod: 'cashOnDelivery',// this.paymentMethod,
      itemsTotal: this.cart.$subTotal,
      truckId: this.cart.$truck.id,
      cartItems: cartItems,

    }).subscribe(function (res) {
      that.srvCart.setPromoCode(that.promoCode, res.discountValue);
      that.isValidatingPromoCode = false;
    }, function (err) {
      that.isValidatingPromoCode = false;
      that.error(err);
    })
  }
  setServiceType(serviceType) {
    this.srvCart.setServiceType(serviceType);
  }
  loadFromSharedCart(sharedCart, confirmed: boolean = false) {
    if (!sharedCart || !sharedCart.length)
      return;
    if (!confirmed && this.utils.askForMembershipModal.askForMembership) {
      this.utils.askForMembershipModal.showModal(() => {
        //Do Nothing?
        this.loadFromSharedCart(sharedCart, true);
      });
      return;
    }
    this.isLoading = true;
    let _self = this;
    this.srvCart.emptyCart(true);
    this.cart = this.srvCart.getCart();
    this.api.viewTruck().subscribe(function (truck) {
      _self.truck = truck.truck;
      _self.srvCart.setTruck(truck.truck);
      _self.isPickupServiceSelected = _self.cart.$truck.services.includes('pickup');
      _self.api.trackEvent(AppEventType.ViewCart);
      let allItems = truck.menu.allItems;
      _.each(sharedCart, function (ci) {
        let item = allItems.find(i => i.itemInfo.id == ci._i);
        if (!item) return;

        let extras = ci._e && _.map(_.split(ci._e, ','), str => {
          let data = _.split(str, '-');
          return { id: Number(data[0]), qty: Number(data[1] || 0) };
        });
        let extrasIds = _.map(extras, e => e.id);
        let selectedExtras = _.filter(_.flatMap(item.extras, e => e.extraItems), e => _.includes(extrasIds, e.id));

        let qty = Number(ci._q || 1);


        _self.srvCart.addItem(_.assignIn(new CartItem(), {
          $item: item.itemInfo,
          extras: _.map(selectedExtras, ei => {
            let res = new CartExtraItem();
            res.$extraItem = ei;
            res.qty = _.find(extras, e => e.id == ei.id).qty || qty;
            ei.qty = res.qty;
            return res;
          }),
          qty: qty,
          notes: "",
        }));
      });
      _self.cart = _self.srvCart.getCart();
      _self.router.navigate(["/cart"], { replaceUrl: true });
      _self.isLoading = false;
    }, err => {
      _self.isLoading = false;
      _self.router.navigate(["/cart"], { replaceUrl: true });
    });
  }

  ngOnInit() {
    //$('.page-side').removeClass('sm-hidden');
  }
  ngOnDestroy() {
    //$('.page-side').addClass('sm-hidden')
  }
  addMore(item: CartItem) {
    let mi = _.find(this.truck.menu.allItems, c => c.itemInfo.id == item.$item.id);
    if (this.srvCart.validateItemToAdd(mi, item.qty, 1, item.unitPrice)) {
      item.qty += 1;
    }

    this.api.trackEvent(AppEventType.AddToCart, item.itemId);
  }
  generateShareUrl() {
    let cItems = this.cart.cartItems.map(function (item) {
      let i: any = {
        _i: item.$item.id,
      };
      if (item.qty)
        i._q = item.qty;
      if (item.extras.length != 0) {
        let extras = _.join(item.extras.map(function (extra) {
          return `${extra.id}-${extra.$extraItem.qty}`
        }), ',');
        if (extras.length)
          i._e = extras;
      }
      return i;

    });
    return location.href + "?c=" + encodeURIComponent(btoa(JSON.stringify(cItems)));
  }
  addLess(item: CartItem) {
    if (item.qty > 0)
      item.qty -= 1;
    // if(item.qty == 0){
    //   this.srvCart.removeCartItem(item);
    // }
  }

  removeItem(item: CartItem) {
    if (!item.qty)
      this.srvCart.removeCartItem(item);
    else {
      this.utils.confirm('', this.trans.instant('Are you sure?')).then((res) => {
        if (res && res.value)
          this.srvCart.removeCartItem(item);
      });
    }
  }

  submitCart(confirmed = false) {
    this.submitted = true;
    // stop the process here if form is invalid
    if (this.submitForm.invalid)
      return;

    if (!this.cart.$truck)
      return;



    if (!this.srvCart.validateCart())
      return;
    if (this.srvCart.getServiceType() == 'catering' && (!this.srvCart.cateringTime || !this.cart.orderInfo.pickupAddress.areaId)) {
      this.cateringIniated = true;
      this.srvCart.isCateringInitiated = true;
      return;
    }
    if (confirmed && this.isPickupServiceSelected && !this.serviceType) {
      this.toaster.warning(this.trans.instant('Please select service type'));
      return;
    }


    // if(this.isPickupServiceSelected && !this.serviceType){
    //   this.modal.getModal('mSelectServiceType').open();
    //   return;
    // }

    //this.cart.orderInfo.serviceType = this.serviceType;

    this.srvCart.saveCart();
    if (this.cart.orderInfo.serviceType == 'pickup')
      this.router.navigate(['/checkout']);
    else
      this.router.navigate(['/pre-checkout']);
  }
  skipCatering() {
    this.cateringIniated = false;
    this.srvCart.isCateringInitiated = false;
  }
  
  continueCatering(){
    if(!this.cart.orderInfo.pickupTime){
      this.utils.toaster.warning(this.trans.instant('Please select catering time'));
      return;
    }
    if(!this.cart.orderInfo.pickupAddress.areaId){
      this.utils.toaster.warning(this.trans.instant('Please select area'));
      return;
    }
    this.cateringIniated = false;
    this.srvCart.isCateringInitiated = false;
    this.cart.orderInfo.pickupTime = this.srvCart.cateringTime;
    this.cart.orderInfo.pickupTimeTo = this.srvCart.cateringTimeTo;

    this.srvCart.setServiceType('catering');
    this.srvCart.saveCart();
    this.submitCart();
  }
  addMoreExtra(item: CartItem, extraItem: CartExtraItem) {
    let foundExtraCat = this.getExtraCategory(item.$item.id, extraItem.$extraItem.catId);
    if (!this.srvCart.validateExtraItemToAdd(extraItem.$extraItem, extraItem.$extraItem.qty, 1, _.sumBy(item.extras, ei => ei.$extraItem.catId == extraItem.$extraItem.catId && ei.$extraItem.qty) + 1, item.qty, foundExtraCat && foundExtraCat.extraCategory))
      return;

    extraItem.$extraItem.qty += 1;
  }

  addLessExtra(item: CartItem, extraItem: CartExtraItem) {
    let foundExtraCat = this.getExtraCategory(item.$item.id, extraItem.$extraItem.catId);
    if (foundExtraCat && (foundExtraCat.extraCategory.minQty || 0) * item.qty > _.sumBy(item.extras, ei => ei.$extraItem.catId == extraItem.$extraItem.catId && ei.$extraItem.qty) - 1) {
      this.trans.get('MIN_Qty_EXTRA', { ec: { minQty: (foundExtraCat.extraCategory.minQty || 0) * item.qty, title: foundExtraCat.extraCategory.title } }).subscribe(msg => {
        this.toaster.error(msg);
      })
      return false;
    }
    if (extraItem.$extraItem.qty > 0)
      extraItem.$extraItem.qty -= 1;
  }

  getExtraCategory(itemId, catItemId) {
    let mi = _.find(this.truck.menu.allItems, c => c.itemInfo.id == itemId);
    return _.find(mi.extras, ec => ec.extraCategory.id == catItemId);
  }
  copyLink() {
    this.utils.toaster.info(this.trans.instant('Link copied successfully'));
  }
  error(msg, title = 'alert') {
    let code;
    if (msg) {
      if (msg.error) {
        if (msg.error.data)
          msg = msg.error.data;
        else
          msg = msg.error;
        code = msg.errorCode;
      }
      if (msg.errorDetail) {
        msg = msg.errorDetail;
        code = msg.code;
      }
      if (msg.errorMessage) {
        msg = msg.errorMessage;
        code = msg.code;
      }
      if (msg.message) {
        msg = msg.message;
        code = msg.code;
      }
    }
    if (code) {
      code = "err_" + code;
      let tmsg = this.trans.instant(code);
      if (tmsg !== code) {
        msg = code
      }
    }
    return this.toaster.warning(msg, title)
  }
}
