
<div class="ikn-modal">
    <ngx-smart-modal #mMakeReservation identifier="mMakeReservation" [dismissable]="true">
        <div class="reservationship-modal">
            <div iknLoader [isActive]='isLoading' class="loader-container"></div>
            <div style="height: 20px;"></div>


            <div class="text-right p-d-10">


                <div class="" *ngIf="!isSaved">

                    <div class="text-center">
                        <img class="membership-logo" [src]="sbOptions?.logoPic" />
                    </div>
                    <div class="seperator"></div>

                    <div class="form-group" *ngIf="sbOptions?.hasMultipleBranches">
                        <label>{{'Branch' | translate}}</label>
                        <select class="form-control" type="text" [(ngModel)]="reservationInfo.branchId">
                            <option *ngFor="let branch of branches" [value]='branch.id'>{{branch.name | iknLocalized}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>{{'Name' | translate}}</label>
                        <input class="form-control" type="text" [(ngModel)]="reservationInfo.name"
                            placeholder="{{'Enter Your Name' | translate}}" />
                    </div>
                    <div class="form-group">
                        <label>{{'Civil Id' | translate}}</label>
                        <input class="form-control" type="text" [(ngModel)]="reservationInfo.civilId"
                            placeholder="{{'Enter Your Civil Id' | translate}}" />
                    </div>
                    <div class="form-group">
                        <label>{{'Reservation Date / Time' | translate}}</label>
                        <input class="form-control" type="datetime-local" [ngModel]="date | date:'yyyy-MM-ddTHH:mm'"
                            (ngModelChange)="date = $event" placeholder="{{'Select Date' | translate}}" />
                    </div>
                    <div class="form-group btn-container text-center">
                        <button class="btn btn-success text-center" style="width:100%" type="submit" (click)="onSave()">
                            <span *ngIf="!isSaving" class='w-100 text-center'>{{'Make Reservation Now' | translate}}</span>
                            <div class="lds-ellipsis" *ngIf="isSaving">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </button>
                    </div>
                </div>
                <div class="" [hidden]="!isSaved">
                    <div class="text-center">
                        <img src="../../assets/images/tick-icon.png" alt="Order Submitted Successfully"
                            class="success-img" />
                        <!-- <img src="../../assets/images/OrderSuccess.svg" alt="Order Submitted Successfully" class="success-img"/> -->
                        <div class="margin-top-10"></div>
                        <h6 class="no-margin font-extraBold">{{'Reservation done successfully' | translate}}</h6>
                        <div class="margin-top-10"></div>


                        <!-- <div class="margin-top-10"></div> -->
                        <div class="d-flex  justify-content-center">
                            <h6 class="text-center font-extraBold  px-4">
                                {{'Reservation Date' | translate}}:
                            </h6>
                            <h6 class="font-extraBold text-success  px-4">
                                <!-- {{'Around' | translate}}  -->
                                {{(reservationResult?.reservationDate) |iknDate:'df'}}
                            </h6>

                        </div>
                        <div class="d-flex justify-content-center">
                            <h6 class="text-center font-extraBold px-4">
                                {{'Reservation Time' | translate}}:
                            </h6>
                            <h6 class="font-extraBold text-success px-4">
                                <!-- {{'Around' | translate}}  -->
                                {{(reservationResult?.reservationDate) |iknDate:'tf'}}
                            </h6>

                        </div>
                        <h5 class="margin-top-10 font-extraBold">{{'Client Name' | translate}}</h5>
                        <h5 class="font-extraBold">{{reservationInfo?.name}}</h5>
                        <!-- <div style="height: 40px"></div> -->
                        <div class="d-flex justify-content-center">
                            
                            <div class='p-2' style="background-color: white;"  id="qrcode"></div>
                        </div>
                        <div class="margin-top-10"></div>
                        <img src="../../assets/images/screenshot.png" style="max-height:60px;" alt="Order Submitted Successfully"
                            class="screen-shot" />
                        <div class="margin-top-10"></div>
                        <h6 class="no-margin order-screen font-bold">
                            {{'Take a screenshot to show when arrive' | translate}}
                        </h6>
                    </div>
                    
                    <div class="form-group btn-container text-center">
                        <a class="btn btn-success" style="width:100%;background:#28a745 !important" target="_blank"
                         (click)="onSave()"
                        [href]="'https://www.google.com/maps/search/?api=1&query=' + reservationResult?.truckLocation?.location?.lat + ',' + reservationResult?.truckLocation?.location?.lng">
                            <span>{{'Get Directions' | translate}}</span>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </ngx-smart-modal>
</div>
<a class="link-icon" >
    <!-- <button class="btn btn-success" (click)="open()">
        {{'Make a reservation' | translate}}
    </button> -->
    <img src="../../assets/images/calendar.png"  (click)="open()" style="max-height:40px; cursor: pointer;">
</a>