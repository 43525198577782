<app-top-header class='top-bar-container' bgStyle="solid" screenTitle="My Orders"></app-top-header>
<div class="page-content">
  <div class="menu-block">
    <div iknLoader [isActive]='isLoading' class="loader-container"></div>
    <div class="search history-search" >
      <button type="submit" class="search_button">
        <i class="fa fa-search"></i>
      </button>
      <input type="text" class="search_term font-extraBold" placeholder="{{'Search' | translate}}" [(ngModel)]="orderNo"
      (ngModelChange)="getMyOrders()">
    </div>
    <h6 class="font-extraBold text-center" *ngIf="!lstOrders?.length && !isLoading && !orderNo">
      <div class="m-t-20"></div>
      {{'You don\'t have any orders yet' | translate}}
    </h6>

    <div class="d-flex flex-column" *ngFor="let order of lstOrders">
      <div class="w-100 p-2 ">
        <div class="border pb-2 w-100 px-2">
          <div class="margin-top-10"></div>
          <div class="d-flex">
            <div class="">
              {{'Order ID' | translate}} - {{order.orderNo}}
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="">
              {{'Order Date' |translate}}: {{order.submittedAt | iknDate:'df'}}
            </div>
            <div class="">
              {{'Order Time' |translate}}: {{order.submittedAt | iknDate: 'tf'}}
            </div>
          </div>
          <div class="text-center">
            {{'Order Status' |translate}}: <span [ngClass]="order.serviceType == 'catering' && (order.status =='Accepted' || order.status == 'PendingPayment') ? 'text-success' : 'text-secondary'"> {{
                      (order.status == 'PendingPayment' && order.serviceType == 'catering' ?
                      'OrderConfirmed' : (order.status == 'Accepted' && order.serviceType == 'catering') ? "Payment Confirmed": 
                      order.status) | translate}}
                    </span>
          </div>
          
          <div class="d-flex justify-content-between" *ngIf="order.status == 'Accepted'">
            <div class="">
              {{'Catering Date' |translate}}: {{order.pickupTime | iknDate:'df'}}
            </div>
            <div class="">
              {{'Catering Time' |translate}}: {{order.pickupTime | iknDate: 'tf'}}
            </div>
          </div>

          <div class="d-flex justify-content-between color-primary m-1">
            <a class='cursor-pointer color-primary' [routerLink]="['/my-orders/' + order.id]">{{'Order Details' | translate}}</a>
            <a  class='cursor-pointer color-blue' *ngIf='order.status == "PendingPayment" && validUntil(order.payment.validUntil)'
            [routerLink]="['/checkout/catering/'+order.id]">{{'Continue to payment' | translate}}</a>
            <a class='cursor-pointer color-primary' *ngIf='order.status == "PendingVendorConfirm"' (click)="cancelOrder(order.id)">
              {{'Cancel Order' | translate}}</a>
          </div>
          <div class='pt-2 text-center  font-weight-bold' *ngIf='order.serviceType == "catering" && order.status == "PendingPayment"'>
            <span *ngIf="validUntil(order.payment.validUntil)" class="text-warning">{{'Note: Payment url valid for' | translate}} ({{order.payment.expiredExpr | iknMinutes}})</span>
            <span *ngIf="!validUntil(order.payment.validUntil)" class="text-danger">{{'Payment url is expired' | translate}}</span>
          </div>
        </div>
     

      </div>
    </div>
  </div>
</div>
<app-order-success-popup (trackOrder)="this.orderNo = $event;"></app-order-success-popup>
<app-footer></app-footer>