import { map } from 'rxjs/operators';
import { UtilsService } from './../utils.service';
import { TruckInfo, OperatingTime } from './../models';
import { ApiService } from './../api.service';
import { param } from 'jquery';
import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TruckBranch } from '../models';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';
@Component({
    selector : 'app-branch-details',
    templateUrl : './branch-details.html',
    styleUrls : ['./branch-details.css']
})
export class BranchDetailsComponent {
    branchId : number;
    isLoading : boolean = false;
    branch : TruckBranch;
    truck : TruckInfo;
    screenTitle ="Branch Details";
    days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    todayOperatingHours : OperatingTime[];
    today : string;
    prevDay : string;
    branchStateText : string= "Closed";
    isOpen : boolean = false;
    foundOperatingHours : OperatingTime;
    todayInNumber: number;
    allOperatingHours: Dictionary<OperatingTime[]> ={};
    exceedingDay : OperatingTime;
    yesterdayOperatingHours: OperatingTime[];
    constructor(private route : ActivatedRoute,private api : ApiService,private utils : UtilsService){
        this.todayInNumber = new Date().getDay();
        this.today =  this.days[this.todayInNumber];
        this.prevDay = this.todayInNumber == 0? this.days[this.days.length-1] : this.days[this.todayInNumber-1];
        
        route.params.subscribe(params=>{
             this.branchId = params['id'];
            this.api.viewTruckBranch(this.branchId).subscribe(branchData =>{
                this.branch = branchData;
            });
            this.api.viewTruck().subscribe(truckData =>{
                this.truck = truckData.truck;
                
                if(this.truck){
                    this.allOperatingHours = _.groupBy(this.truck.operatingHours , operatingHour => operatingHour.day);
                    this.yesterdayOperatingHours = _.filter(this.truck.operatingHours, oh => oh.day == this.prevDay);
                    this.exceedingDay = _.find(this.yesterdayOperatingHours, c=> (utils.parseTimeSpan(c.operatingFrom).aen  == 'pm') 
                     && utils.parseTimeSpan(c.operatingTo).aen == 'am');
                    
                    this.todayOperatingHours = _.filter(this.truck.operatingHours, oh => oh.day == this.today);
                    if(this.todayOperatingHours || this.exceedingDay)
                    {
                       this.foundOperatingHours = (this.exceedingDay != null &&utils.isNowBetween("00:00:00" ,this.exceedingDay.operatingTo) ) ? this.exceedingDay : 
                       _.find(this.todayOperatingHours, c => utils.isNowBetween(c.operatingFrom,c.operatingTo) || c.state == "24 hours");
                         
                       this.isOpen = this.foundOperatingHours? true : false
                       && this.foundOperatingHours.state  != 'closed';   
                       this.branchStateText = this.isOpen? "Open" : "Closed"; 
                       
            
                    }
                }    
            });
        })
    }
    

}