<form [formGroup]="loginForm" #frmLogin='ngForm'>
    <div>
        <h3 class="m-0 w-100 f-boldest">
            {{'Email' | translate}}
        </h3>
    </div>
    <div class="input-wrapper d-flex align-items-center justify-content-between">
        <div class="flex-grow-1">
            <input type="text" [(ngModel)]="username" class=""
                placeholder="{{'Enter username or phone number' | translate}}" formControlName="username">
                <div *ngIf="submitted && (fl.username.errors)" class="invalid-feedback">
                    <div *ngIf="fl.username.invalid">{{'Email is required'|translate}}</div>
                </div>
        </div>
    </div>
    <div class="mt-4">
        <h3 class="m-0 w-100  f-boldest">
            {{'Password' | translate}}
        </h3>
    </div>
    <div class="input-wrapper d-flex align-items-center justify-content-between">
        <div class="flex-grow-1">
            <input type="password" [(ngModel)]="password" class="" placeholder="{{'Enter your password' | translate}}"
                formControlName="password">
                <div *ngIf="submitted && (fl.password.errors)" class="invalid-feedback">
                    <div *ngIf="fl.password.invalid">{{'Password is required'|translate}}</div>
                </div>
        </div>
    </div>



</form>

<div class="mt-4 d-flex flex-column ">
    <form #frmQuickRegister='ngForm' [formGroup]='quickRegisterForm'>
        <div class='py-2'>
            <input id="quick_register" class='checkbox  form-control' formControlName='quickRegister' type="checkbox"
                [(ngModel)]="quickRegister">
            <label for="quick_register" class="f-boldest color-primary">
                {{'Quick Register' | translate}}

            </label>

        </div>
        <div [hidden]='!quickRegister'>
            <div>
                <h3 class="m-0 w-100 f-boldest">
                    {{'Name' | translate}}
                </h3>
            </div>
            <div class="input-wrapper d-flex align-items-center justify-content-between">
                <div class="flex-grow-1">
                    <input type="text" [(ngModel)]="displayName" class=""
                        placeholder="{{'Enter your name' | translate}}" formControlName="displayName">
                        <div *ngIf="submitted && (fq.displayName.errors)" class="invalid-feedback">
                            <div *ngIf="fq.displayName.invalid">{{'Name is required'|translate}}</div>
                        </div>
                </div>
            </div>
            <div class="mt-4">
                <h3 class="m-0 w-100  f-boldest">
                    {{'Phone Number' | translate}}
                </h3>
            </div>
            <div class="input-wrapper">
                <ngx-intl-tel-input [cssClass]="'phone-input'" [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="false" [enablePlaceholder]="true"
                    [searchCountryPlaceholder]="'Search Country' | translate" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.All]" [selectFirstCountry]="false"
                    [selectedCountryISO]="selectedCountryCode" [tooltipField]="TooltipLabel.Name"
                    [phoneValidation]="true" [separateDialCode]="true" [(ngModel)]="phoneNumber" id="phone-id"
                    name="phoneNumber" formControlName="phoneNumber"></ngx-intl-tel-input>
                <div *ngIf="submitted && (fq.phoneNumber.errors)" class="invalid-feedback">
                    <div *ngIf="fq.phoneNumber.invalid">{{'Invalid Phone number'|translate}}</div>
                </div>
            </div>
        </div>

    </form>

    <div class="mt-4 d-flex flex-column justify-content-between align-items-center">

        <button class="btn btn-primary bg-primary px-5" (click)="login()">
            <span *ngIf="!isLoading">{{'Login' | translate}}</span>
            <div iknLoader [isActive]='isLoading' class="loader-container"></div>
        </button>
        <a class="py-4" [routerLink]="['/forget-password', {returnTo: returnTo}]">{{'Forget Password' | translate}}</a>
        <div class="text-center">
            {{"Don't have account?" | translate}} <a [routerLink]="['/register', {returnTo: returnTo}]">{{'Create your
                account now' | translate}}</a>
        </div>
    </div>
</div>