<div class="ikn-modal area-selector">
  <ngx-smart-modal #mSelectArea identifier="mSelectArea">
    <div class="comp-area">
      <!-- <button class="dismiss-btn" (click)="modal.getModal('mSelectArea').close()">
        <i class="fa fa-times"></i>
      </button> -->
      <!-- <div class="m-t-30"></div> -->
      <h1 class="ikn-modal-header font-extraBold">
        {{'Choose Your Delivery Location' | translate}}
      </h1>
  
      <div class='col'>
        <div class="wrap font-extraBold">
          <div class="search">
              <button type="button" class="search_button">
                  <i class="fa fa-map-marker"></i>
              </button>
              <input type="text" class="search_term" placeholder="{{'Search for area' | translate}}" [(ngModel)]="searchText">
          </div>
        </div>
  
          <!-- <div class="input-wrapper ">
            <input type="text" class="" placeholder="" [(ngModel)]="searchText">
            <i class="im im-search"></i>
          </div> -->
        </div>
      <div class='area-selector-container font-extraBold'>
      <div name="selected" *ngFor="let governate of governates" >
        <div *ngIf="(governate.areas | filter:searchText) as results">
            <div class="governate" (click)="onGovernate(governate)" [ngClass]="{'active': governate.flag}" *ngIf="results.length">
              <i class="anglePosition" [ngClass]="{'im im-angledown': !governate.flag && !searchText}"></i>
              <i class="anglePosition" [ngClass]="{'im im-angleup': governate.flag || searchText}"></i>
              <a class="governatename ar-right">{{governate.name | iknLocalized}} </a>
            </div>
            <div *ngIf="governate.flag || searchText">
              <p class="area text-right" *ngFor="let area of results"
                (click)="onSave(area)">
                <a class="areaname">{{area.name | iknLocalized}}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ngx-smart-modal>
  </div>
  <span (click)="selectArea()" class="area-label">
    <!-- <span *ngIf="selectedArea">{{selectedArea.name | iknLocalized}}</span> -->
    <!-- <span *ngIf="!selectedArea">{{ 'Select Area' | translate }}</span>) -->
    <span class="color-primary" *ngIf="!hideSelectLabel">{{ 'Change' | translate }}</span>
    <span>
  