<!--Only On Home Screen we should show this-->
<div class='page-container'>
  <div class='position-fixed' style='z-index:11;bottom:0;left:0;right:0;top:0;background-color: rgba(0,0,0,0.4);'
    *ngIf='addToHomeVisible'>
    <div class='position-absolute' style='left:0;right:0;bottom:10px;z-index:12' *ngIf='addToHomeVisible' [@slideInUpOnEnter]
    [@slideOutDownOnLeave]>
    <div class='p-2'>
      <div
        class='d-flex flex-column align-content-center jsutify-content-center border shadow rounded p-2 add-to-home-section '
        style='background-color: white;'>
        <div class='d-flex jsutify-content-between align-items-between'>
          <div class='py-1 f-bolder t-normal flex-grow-1 color-primary'>{{'Add To Home Screen' | translate}}</div>
          <div class='color-primary cursor-pointer' (click)='addToHomeVisible=!addToHomeVisible'>
            <u>{{'Cancel' | translate}}</u></div>
        </div>
        <div class='border-bottom'></div>
        <div class='p-2'>
          {{'This website has app functionality. Add it to your home screen to use it in fullscreen and while offline' | translate}}
        </div>
        <div class='border-bottom'></div>
        <div class='p-2 d-flex jsutify-content-center'>
          <div class='px-2 flex-grow-1'> {{'1) Press the Share button' | translate}} <img
              src='/assets/images/Share.png' class='mx-1' style='max-height: 1.5em;  object-fit:contain'>
            {{"on the menu bar below" |translate}}</div>

        </div>
        <div class='p-2 d-flex jsutify-content-center'>
          <div class='px-2 flex-grow-1'> {{"2) Press Add to Home Screen" |translate}} <img
              src='/assets/images/AddToHome.png' class='mx-1'
              style='max-height: 1.5em; object-fit:contain;vertical-align: middle!important;'></div>
        </div>

      </div>
    </div>
  </div>
  </div>
  
<router-outlet></router-outlet>
</div>
<div class="page-side sm-hidden">
  <div class="page-side-inner">
    <!-- <div class="track-order">
            <a [routerLink]="['/track-order']" class="text-shadow">{{'Track Order' | translate}}</a>
          </div>
          <div class="truck-about-us"
          [style.display]="allowAboutUs ? 'block' : 'none'">
            <a [routerLink]="['/about-us']" class="text-shadow">{{'About Us' | translate}}</a>
          </div>
          <div class="lang-switcher top-55" *ngIf="useMultiVendor">
            <app-branch-selector
                    (branchesLoaded)="onBranchesLoaded($event)"

                    [(branch)]="branchId" [notPickup]="true" class="pull-heading-right"></app-branch-selector>
          </div> -->

    <!-- <div class="page-side-logo">
            <a href=""><img [src]="logo" alt=""></a>
        </div> -->

  </div>
</div>
<div class="ikn-modal">
  <ngx-smart-modal #mSoonestTime identifier="mSoonestTime" [dismissable]="true">
    <div class="sevice-type-modal">


      <div class="margin-top-10"></div>
      <h1 class="ikn-modal-header font-extraBold">
        <span *ngIf="!isPickupService">{{'Soonest Delivery Time' | translate}}</span>
        <span *ngIf="isPickupService">{{'Soonest Pickup Time' | translate}}</span>
      </h1>
      <div class="margin-top-10"></div>
      <div class="text-right p-d-10">

        <div class="ikn-modal-content odrer-type-selector-container text-center">
          <h2 class="font-normal">{{(expectedDeliveryTime) | iknDate:'daynear'}}</h2>
          <div *ngIf="!(expectedDeliveryTimeTo)">
            <h2 class="text-success font-normal">{{(expectedDeliveryTime) | iknDate:useDateOnly ? 'df' : 'dtf'}}</h2>
          </div>
          <div *ngIf="expectedDeliveryTimeTo">
            <h2 class="text-success font-normal">{{(expectedDeliveryTime) | iknDate:'df'}}</h2>
            <h3 class="time-note text-success" *ngIf="!useDateOnly">
              {{'From Time' | translate}} {{(expectedDeliveryTime) | iknDate:'tf'}}
              {{'To Time' | translate }} {{(expectedDeliveryTimeTo) | iknDate:'tf'}}
            </h3>
          </div>
          <div style="height:20px"></div>

        </div>
        <div class="form-group btn-container text-center">
          <button class="btn btn-success" style="width:100%" type="submit" (click)="closeSoonestTimeModal()">
            <span>{{'Confirm' | translate}}</span>
          </button>
        </div>

      </div>
    </div>
  </ngx-smart-modal>
</div>
<div>
  <app-ask-for-membership></app-ask-for-membership>
</div>
<script>
  initPage();
</script>