<div iknLoader [isActive]='isLoading' class="loader-container"></div>
<div class="page-content mt-0 mr-0 pr-0 px-0">
    <app-top-header class='top-bar-container bg-white' bgStyle="solid" [screenTitle]="screenTitle | translate"></app-top-header>
    <div class="wrap " *ngIf='!isLoading && branch'>
        <div class="mx-1 w-100"
            *ngIf='branch.location?.location?.lat && branch.location?.location?.lng'>
            <agm-map [fullscreenControl]="true" [zoomControl]="true" [latitude]="branch.location.location.lat" [longitude]="branch.location.location.lng"
                [zoom]='15' style="height: 300px;width:100%">
                <agm-marker [latitude]="branch.location.location.lat"
                    [longitude]="branch.location.location.lng" [label]='({ text: branch.name.ar })'
                    class='marker-label'>
                </agm-marker>
            </agm-map>
        </div>
        <div class='p-4 d-flex justify-content-center align-items-center'>
            <a class='border border-black  cursor-pointer px-4 py-2  f-bolder t-medium'
                [href]='"tel://" + branch?.location?.contactPhone'>
                {{'Call Branch' | translate}}
            </a>
            <div class='mx-2' *ngIf='branch.location?.location?.lat && branch.location?.location?.lng'>
            </div>            <a class='border border-black round cursor-pointer  px-4 py-2  f-bolder  t-medium'
                *ngIf='branch?.location?.location?.lat && branch?.location?.location?.lng'
                [href]="'https://www.google.com/maps/search/?api=1&query=' + branch?.location?.location?.lat + ',' + branch?.location?.location?.lng">
                {{'Get Directions' | translate}}
            </a>
        </div>
        <div class="bg-light" style="height: 15px;"></div>
        <div class="p-2 d-flex align-items-center">
            <span>
                <span [ngClass]="isOpen? 'text-success px-2' : 'text-danger' ">
                    <i class="fa fa-circle"></i> {{branchStateText | translate}}
                </span> 
                <span *ngIf="isOpen && foundOperatingHours?.state != '24 hours'">
                     {{'Until' | translate}}
                </span>
                <span class="mx-1" *ngIf="foundOperatingHours">
                    <span *ngIf="foundOperatingHours?.state != '24 hours'">
                         {{foundOperatingHours?.operatingTo | iknTime}}
                    </span>
                    <span *ngIf="foundOperatingHours?.state == '24 hours'">
                        {{'All Day' | translate}}
                    </span>
                </span>
            </span>
        </div>
        
        <div class="bg-light" style="height: 15px;"></div>
        <div class="">
            <div class="d-flex pt-2 px-3 bg-white" *ngFor="let day of days">
                <div class="font-bold">{{day | translate}}: </div>
                <div class="mx-1">
                    <div *ngIf="allOperatingHours[day] != null && allOperatingHours[day] != undefined">

                        <span *ngFor ="let time of allOperatingHours[day]; let isLast = last">
                            <span *ngIf="time.state != '24 hours'">
                                {{time.operatingFrom | iknTime}} - {{time.operatingTo | iknTime}}
                            </span>
                            <span *ngIf="!isLast"> , </span>
                            <span *ngIf="time.state == '24 hours'">
                                {{'All Day' | translate}}
                            </span>
                        </span>
    
                    </div>
                    <div *ngIf="allOperatingHours[day] == null || allOperatingHours[day] == undefined">
                        {{'Closed' | translate}}
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>