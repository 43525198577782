<app-top-header class='top-bar-container' bgStyle="solid" screenTitle="Create Account"></app-top-header>
<div class="page-content px-4 my-4">
  <form [formGroup]="submitForm" (ngSubmit)="register()">
    <div iknLoader [isActive]='isLoading' class="loader-container"></div>
    <div class="checkout-block" *ngIf="!isLoading">
      <div class="margin-top-10"></div>
      <div class="input-wrapper">
        <label for="name" class="font-bold lbl-txt">{{'Name' | translate}} <span class="required-color">*</span></label>
        <input type="text" required placeholder="Johndoe" [(ngModel)]="data.profile.displayName" formControlName="name">
        <div *ngIf="(submitted || f.name.touched) && f.name.errors" class="invalid-feedback">
          <div *ngIf="f.name.errors.required">{{'Name is required'|translate}}</div>
        </div>
      </div>

      <div class="input-wrapper">
        <label for="phoneNumber" class="font-bold lbl-txt">{{'Phone Number' | translate}} <span
            class="required-color">*</span></label>
        <ngx-intl-tel-input [cssClass]="'phone-input'" [preferredCountries]="preferredCountries"
          [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
          [searchCountryPlaceholder]="'Search Country' | translate" [searchCountryFlag]="true"
          [searchCountryField]="[SearchCountryField.All]" [selectFirstCountry]="false"
          [selectedCountryISO]="CountryISO.Kuwait" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
          [separateDialCode]="true" [(ngModel)]="phoneNumber" id="phone-id" name="phoneNumber"
          formControlName="phoneNumber"></ngx-intl-tel-input>
        <div *ngIf="(submitted || f.phoneNumber.touched) && f.phoneNumber.errors" class="invalid-feedback">
          <div *ngIf="!f.phoneNumber.errors.required && !phoneNumber">{{'Phone number is required'|translate}}
          </div>
          <div *ngIf="f.phoneNumber.invalid">{{'Invalid Phone number'|translate}}</div>
        </div>
      </div>

      <div class="input-wrapper">
        <label for="email" class="font-bold lbl-txt">{{'E-mail' | translate}} <span
            class="required-color">*</span></label>
        <input type="email" placeholder="johndoe@gmail.com" [(ngModel)]="data.profile.email" formControlName="email"
          required>
        <div *ngIf="(submitted || f.email.touched) && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">{{'E-mail is required'|translate}}</div>
          <div *ngIf="f.email.errors.email">{{'Invalid E-mail'|translate}}</div>
        </div>
      </div>

      <div class="input-wrapper">
        <label for="password" class="font-bold lbl-txt">{{'Password' | translate}} <span
            class="required-color">*</span></label>
        <input [type]="showPass ? 'text' : 'password'" placeholder="{{'Password' | translate}}"
          formControlName="password" [(ngModel)]="data.password" required class="p-r-65">
        <a class="show-btn color-primary" (click)="showPassword()" *ngIf="data.password">
          {{showPass ? ('Hide'|translate) : ('Show'|translate)}}
        </a>
        <div *ngIf="(submitted || f.password.touched) && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">{{'Password is required'|translate}}</div>
        </div>
      </div>

      <div class="input-wrapper">
        <label for="confirmPassword" class="font-bold lbl-txt">{{'Confirm Password' | translate}} <span
            class="required-color">*</span></label>
        <input type="password" placeholder="{{'Password' | translate}}" pattern="{{data.password}}"
          formControlName="confirmPassword" [(ngModel)]="confirmPassword" required id="confirmPassword">
        <div *ngIf="(submitted || f.confirmPassword.touched) && f.confirmPassword.errors" class="invalid-feedback">
          <div *ngIf="f.confirmPassword.errors.required">{{'Confirm Password is required'|translate}}</div>
          <div *ngIf="f.confirmPassword.errors.pattern">{{'Password & Confirm Password do not match.'|translate}}
          </div>
        </div>
      </div>

      <p class="terms-txt font-bold">
        {{'agree_terms_txt' | translate:truck?.truck }} <span class="text-link color-primary"
          [routerLink]="['/terms']">{{'Terms & Conditions' | translate}}</span>
      </p>


    </div>
  </form>
 
</div>
<div class="page-btn f-boldest t-large" [@slideInUpOnEnter] *ngIf="!isLoading">
  <a class="btn btn-primary bg-primary btn-cart" (click)="register()">{{'Register' | translate}}</a>
</div>
<app-footer></app-footer>