import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { TruckInfo, TruckViewDto, MenuItem, CartItem, CategoryInfo, CategoryView, TruckMenu, AppEventType, TruckPic } from '../models';
import { Observable, Subscription } from 'rxjs';
import { UtilsService } from '../utils.service';
import { CartService } from '../cart.service';
import { Cart } from '../cart';
import { Router } from '@angular/router';
import { timeout } from 'q';
import * as _ from 'lodash';
import { ExtraCategoryAlertComponent } from '../extra-category-alert/extra-category-alert.component';
import { MenuAlertComponent } from '../menu-alert/menu-alert.component';
import { environment } from 'src/environments/environment';
import { getAttrsForDirectiveMatching } from '@angular/compiler/src/render3/view/util';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, slideInUpOnEnterAnimation, slideOutDownOnLeaveAnimation } from 'angular-animations';
let lastOpenedCategoryId = undefined;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  animations: [fadeInOnEnterAnimation(), fadeOutOnLeaveAnimation(),slideInUpOnEnterAnimation({ delay: 1000 }), slideOutDownOnLeaveAnimation({ delay: 100 })]
})
export class MenuComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(ExtraCategoryAlertComponent) extraCatAlertSelector: ExtraCategoryAlertComponent;
  @ViewChild(MenuAlertComponent) menuAlertSelector: MenuAlertComponent;
  cart: Cart
  lastOpenedCategoryId: any = lastOpenedCategoryId
  menu: TruckMenu;
  cateringItems: MenuItem[];
  cateringIniated:boolean = false;
  truckPics: TruckPic[];
  isLoadingMenu = false;
  isOpen = true;
  isWorkingNow = true;
  allowPreOrder = false;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    arrows: false,
    infinite: true,
  }
  isShipping = false;
  private subMenuChanged: Subscription;
  private subMenuChanging: Subscription;
  env = environment;
  constructor(private api: ApiService, private router: Router, public utils: UtilsService, public srvCart: CartService) {
    this.cart = srvCart.getCart();
    if(this.cart.serviceType_ex == 'catering'){
      this.router.navigate(['catering-menu'], {
        skipLocationChange:true,
        replaceUrl: true
      });
      return;
      srvCart.setServiceType('','');
    }
    this.api.trackEvent(AppEventType.ViewMenu)
    if (environment.default_category_id) {
      this.api.viewCategory(environment.default_category_id).subscribe(cat => {
        if (cat.subCategories && cat.subCategories.length) this.router.navigate(['/sub-category/' + cat.categoryInfo.id]);
        else this.router.navigate(['/categories/' + cat.categoryInfo.id + '/items']);

      });
      return;
    }
    this.subMenuChanging = api.menuChanging.subscribe(loadingState => {
      this.isLoadingMenu = loadingState;
    });
    this.subMenuChanged = api.menuChanged.subscribe(d => {
      if(!d)
        return;
      this.menu = d;
      this.cateringItems = _.filter(this.menu.allItems, d=>_.includes(d.itemInfo.services, 'catering'));
      console.log(this.cateringItems);
      let outOfStockItems = _.filter(this.menu.allItems, i => i.itemInfo.isStocked && !i.itemInfo.stockQty);
      this.outOfStockItems = outOfStockItems.length;
    });
    //console.log(lastOpenedCategoryId);
  }
  get availableItems(){
    return (this.cart?.serviceType_ex == 'catering'  ? this.menu && (this.menu.allItems.length - this.outOfStockItems) : this.cateringItems && this.cateringItems.length) || 0;
  }
  truck?: TruckViewDto;
  searchText: string;
  category: CategoryView;
  isLoading: boolean = false;
  showTermsLink: boolean = environment.terms_at_end_of_menu;
  outOfStockItems = 0;
  androidLink?: string;
  iosLink?: string;
  showMostSoldItems: boolean = false;
  showRecentlyAddedItems: boolean = false;
  mostSellingCat?: CategoryView;
  recentlyAddedCat?: CategoryView;
  lastOpenedCategory() {
    return this.lastOpenedCategoryId;
  }
  openCategory(cat, force = false) {
    let that = this
    setTimeout(function () {
      //console.log(cat.id);
      if (force || cat.id != lastOpenedCategoryId)
        lastOpenedCategoryId = cat.id;
      else
        lastOpenedCategoryId = null;
      if (!that.lastOpenedCategoryId)
        that.lastOpenedCategoryId = lastOpenedCategoryId;
      else
        that.lastOpenedCategoryId = null;
    }, 500);
  }
  ngAfterViewInit() {

    let offset: any = $($(".expanded")[0]).offset();
    offset = (offset && (offset.top - 60)) || 0;

    // $([document.documentElement, document.body]).animate({
    //   scrollTop: offset
    // }, 500);
  }
  ngOnInit() {
    $('.home-screen-only').css({ visibility: 'visible', display: 'flex' });
    //$('.page-side').removeClass('sm-hidden');
    this.isLoading = true;
    
    this.api.viewTruck().subscribe(d => {
      this.truck = d;
      if(d.truck.allowShippingOutsideCountry){
      this.isShipping = this.cart.orderInfo.pickupAddress.countryId
      && this.cart.orderInfo.pickupAddress.countryId != d.truck.defaultCountry.id
      }
      console.log('notes', this.truck.truck.notes);
      this.isLoading = false;
      if (!this.utils.isNavigationAllowed(this.truck.truck)) {
        this.router.navigate(['/home']);
        return;
      }
      let outOfStockItems = _.filter(this.truck.menu.allItems, i => i.itemInfo.isStocked && !i.itemInfo.stockQty);
      this.outOfStockItems = outOfStockItems.length;

      let _self = this;
      this.menu = this.truck.menu;
      this.utils.fbTrack('PageView');
    }, err => {
      this.isLoading = false;
    })
    this.api.viewSBOptionsDetails().subscribe(res => {
      this.isOpen =
      this.utils.isNowBetween(res.operatingFrom, res.operatingTo);
      this.isWorkingNow = this.isOpen;
      if(!this.utils.isNavigationAllowed(res)){
        this.isOpen = false;
        this.allowPreOrder = false;
        this.isWorkingNow = false;
      } else if (this.allowPreOrder){
        this.isOpen = true;
      }

      if (res.pics && res.pics.length > 0)
        this.truckPics = _.filter(res.pics, c => c.showOnSlider == true);
      if(!this.truckPics || !this.truckPics.length)
        this.truckPics = _.filter(res.pics, d=>d.isMain);
      if(this.truckPics && this.truckPics.length)
        this.truckPics = _.sortBy(this.truckPics, d=>d.sortNo);


      if (res && res.simpleBrokerOptions) {
        this.androidLink = res.simpleBrokerOptions.androidAppLink;
        this.iosLink = res.simpleBrokerOptions.iosAppLink;
        this.showMostSoldItems = res.simpleBrokerOptions.showMostSoldItems;
        this.showRecentlyAddedItems = res.simpleBrokerOptions.showRecentlyAddedItems;
        if (this.showMostSoldItems) {
          this.mostSellingCat = {
            categoryInfo: {
              id: -1,
              title: { en: 'Most Selling', ar: 'الأكثر مبيعاً' },
              mainPic: res.simpleBrokerOptions.mostSoldItemsImg,
            }
          } as CategoryView;
        }

        if (this.showRecentlyAddedItems) {
          this.recentlyAddedCat = {
            categoryInfo: {
              id: -2,
              title: { en: 'Recently Added', ar: 'المضافة حديثاً' },
              mainPic: res.simpleBrokerOptions.recentlyAddedItemsImg,
            }
          } as CategoryView;
        }

      }
      if (res.simpleBrokerOptions && res.simpleBrokerOptions.menuAlert && res.simpleBrokerOptions.menuAlert.alertEnabled) {
        setTimeout(() => this.menuAlertSelector.showMenuAlert(() => { }, res.simpleBrokerOptions.menuAlert));
      }
    });
  }
  //that's not correct

  // getLoadedTruck(){ // if change branch in menu screen, must load new truck menu
  //   this.cart = this.srvCart.getCart();
  //   if(this.truck && this.truck.truck)
  //     this.truck.truck = this.cart.$truck;
  //   if(this.cart.$truck){
  //     let outOfStockItems = _.filter(this.cart.$truck.menu.allItems, i=>i.itemInfo.isStocked && !i.itemInfo.stockQty);
  //     this.outOfStockItems = outOfStockItems.length;
  //     this.availableItems = this.cart.$truck.menu.allItems.length - this.outOfStockItems;
  //   }
  //   return this.cart;
  // }

  checkExtraCartCat(item) {
    let _self = this;
    this.category = this.srvCart.getCart().baseCategory(item.itemInfo.id);
    if (this.cart.cartItems.length != 0) {
      let foundCat = _.find(this.cart.cartItems, ci => this.getCatId(ci.$item.id) == this.category.categoryInfo.id);
      if (!foundCat &&
        ((this.category.categoryInfo.extraDeliveryCharge &&
          this.category.categoryInfo.extraDeliveryCharge != 0) ||
          (this.category.categoryInfo.extraDeliveryTimeMinutes &&
            this.category.categoryInfo.extraDeliveryTimeMinutes != 0)
        ))
        setTimeout(() => this.extraCatAlertSelector.selectExtraCatAlert(() => { }, _self.category));
    }
  }

  getCatId(itemId) {
    var baseCat = this.cart.baseCategory(itemId);
    if (baseCat)
      return baseCat.categoryInfo.id
  }
  ngOnDestroy() {
    if (this.subMenuChanged)
      this.subMenuChanged.unsubscribe();
    $('.home-screen-only').css({ visibility: 'hidden', display: 'none' })
    //$('.page-side').addClass('sm-hidden');
  }
  startOrder() {
    if (this.cart.cartItems && this.cart.cartItems.length) {
      this.router.navigate(['/pre-checkout'])
    }
  }
  onCategoryClick(cat: any) {
    if (cat.subCategories && cat.subCategories.length) this.router.navigate(['/sub-category/' + cat.id]);
    else this.router.navigate(['/categories/' + cat.categoryInfo.id + '/items']);
  }
  checkOutOfStock(item) {
    return this.srvCart.isOutOfStock(item);
  }
  addItem(item: MenuItem) {
    this.checkExtraCartCat(item);
    let qty = 1;
    var found = _.find(this.cart.cartItems, c => c.$item.id == item.itemInfo.id);
    if (found) {
      found.qty += 1;
      qty = found.qty;
    } else {
      this.srvCart.addItem(_.assignIn(new CartItem(), {
        $item: item.itemInfo,
        extras: [],
        qty: 1,
        notes: "",
      }));
    }
    item.qty += 1;
    this.utils.fbTrack('AddToCart');
    this.api.trackEvent(AppEventType.AddToCart, item.itemInfo.id, { qty: qty });
    this.srvCart.saveCart();
  }
  removeItem(item: MenuItem) {
    var found = _.find(this.cart.cartItems, c => c.$item.id == item.itemInfo.id);
    if (found) {
      found.qty -= 1;
      item.qty -= 1;
      if (found.qty == 0) {
        this.srvCart.removeCartItem(found);
      }
    }
    this.srvCart.saveCart();
  }

  navigateToUrl(pic){
    if(pic.linkedItem)
      this.router.navigate(['/item/' + pic.linkedItem.id]);
    else if(pic.linkedCategory)
      this.router.navigate(['/categories/' + pic.linkedCategory.id + '/items']);
    else if(pic.linkedUrl)
      window.location.href = pic.linkedUrl;
  }
}
