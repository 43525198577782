<div class="page-btn font-extraBold" *ngIf='cart?.cartItems?.length && !env.view_catalog_only' routerLink='/cart'>
    <a href="javascript:{}" class="btn btn-primary d-flex justify-content-between align-items-center">

        <div>{{cart?.$totalItemsQty}}</div>
        <div class='flex-grow-1 text-center'>{{'Review Cart' | translate}}</div>
        <div>{{cart?.$subTotal | iknCurrency}} {{cart?.$truck?.defaultCountry?.currencyCode | iknLocalized}}</div>

    </a>
</div>
<app-top-header class="top-bar-container" [bgStyle]="'solid'"
    [screenTitle]="(category?.categoryInfo?.title | iknLocalized) || ' '"></app-top-header>
<div class="page-content">
    <div class="m-t-15"></div>
    <div iknLoader [isActive]='isLoading' class="loader-container"></div>
    <div class="card-column" *ngFor="let cat of categories">
        <div class="menu-card c-pointer" routerLink="/categories/{{cat?.categoryInfo.id}}/items">
            <div class="img hide-image"><img class="subcat-img"
                    [src]="cat?.categoryInfo?.mainPic || 'assets/images/logo.png'" alt=""></div>
            <p class="card-title no-margin">{{cat?.categoryInfo?.title | iknLocalized}}</p>
            <div class="card-main-items">
                <div class="card-counts bg-primary">
                    <p class="no-p-m font-bold">{{cat?.items?.length}}</p>
                    <p class="no-p-m">{{'Items' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
<br />
<br />