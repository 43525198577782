<div class="page-btn font-extraBold" *ngIf='cart?.cartItems?.length && !env.view_catalog_only' routerLink='/cart'>
  <a href="javascript:{}"
    class="btn btn-primary d-flex justify-content-between align-items-center">

    <div>{{cart?.$totalItemsQty}}</div>
    <div class='flex-grow-1 text-center'>{{'Review Cart' | translate}}</div>
    <div>{{cart?.$subTotal | iknCurrency}} {{cart?.$truck?.defaultCountry?.currencyCode | iknLocalized}}</div>

  </a>
</div>
<app-top-header class="top-bar-container" [hideLogo]="true" [handleScroll]='true'></app-top-header>
<div class="slider-container" *ngIf="!isLoading" [@fadeInOnEnter]>
  <!-- <div class="overlay-pic"  *ngIf="truck && !isOpen">
    <img src="{{'close_img' | translate}}" />
  </div> -->
  <div class="slider-img">
    <ngx-slick-carousel *ngIf="truckPics.length" class="carousel home-slide" #slickModal="slick-carousel"
      [config]="slideConfig">
      <div ngxSlickItem *ngFor="let pic of truckPics" class="slide" width="100%">
        <img [src]="pic.pic || '/assets/images/logo.png'" alt="" (click)="navigateToUrl(pic)">
      </div>
    </ngx-slick-carousel>
  </div>
  <div class="clearfix"></div>
</div>
<div class="page-content">


  <div class="menu-block mt-2 mb-2" *ngIf="!isLoading" [@fadeInOnEnter]>
    <div class="d-flex truck-info-min cursor-pointer" [routerLink]="['/info']">
      <div class="med-logo" *ngIf="truck?.truck?.logoPic">
        <img [src]="truck?.truck?.logoPic">
      </div>
      <div class="d-flex flex-column mr-2 ml-2 flex-grow-1">
        <h3 class="m-0 t-normal color-primary">{{truck?.truck?.name | iknLocalized}}</h3>
        <div class="truck-notes t-medium text-grey lh-1 mt-2">
          {{truck?.truck?.notes | iknLocalized}}
        </div>
      </div>
      <div class="d-flex">
        <span><i class="far fa-angle-right"></i></span>
      </div>
    </div>
    <div class="d-flex flex-column truck-info-min mt-2" *ngIf="!env.view_catalog_only">
      <div class="d-flex justify-content-between align-items-top cursor-pointer"
      [routerLink]="['/service']">
        <!-- <h3 class="text-nowrap m-0">{{'Service and area' | translate}}: </h3> -->
        <div class="px-2 flex-grow-1">
          <span
            *ngIf="(isShipping && !cart.orderInfo.pickupAddress.countryId) || (!isShipping && ( (!cart.orderInfo.pickupAddress.areaId || !cart.serviceType_ex) && cart.orderInfo.serviceType != 'pickup'))">{{'Choose
            Service - Area' | translate}}
          </span>

          <span *ngIf="!isShipping && cart.orderInfo.pickupAddress.areaId &&  cart.serviceType_ex"><b
              class="service-type-info">{{'srv_' + cart.serviceType_ex | translate}}</b>

            <span *ngIf="cart.orderInfo.serviceType == 'pickup'">
              <span *ngIf='cart.$branch'> - {{ cart.$branch?.name | iknLocalized}}</span>
              <span *ngIf='!cart.$branch'> - {{'Main Branch' | translate}}</span>

            </span>
            <span *ngIf="cart.orderInfo.serviceType != 'pickup'"> - {{cart.orderInfo.pickupAddress.areaName |
              iknLocalized}}</span>
          </span>
          <span *ngIf="isShipping && cart.orderInfo.pickupAddress.countryId &&  cart.serviceType_ex"><b
              class="service-type-info">{{'srv_' + cart.serviceType_ex | translate}}</b>


            <span class='px-2'>{{cart.orderInfo.pickupAddress.countryName | iknLocalized}} -
              {{cart.orderInfo.pickupAddress.governateName | iknLocalized}}</span>
          </span>

        </div>
        <div>
          <span><i class="far fa-angle-right"></i></span>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center cursor-pointer"
        [class.disabled]="!cart.serviceType_ex || cart.serviceType_ex == 'soonest_delivery' || cart.serviceType_ex == 'pickup'"
        [routerLink]="['/time-date']">
        <!-- <div class="d-flex no-wrap">
          <h3 class="text-nowrap m-0">{{'Time and date' | translate}}: </h3>
        </div> -->
        <div class="px-2 flex-grow-1 d-flex align-items-center">
          <span *ngIf="!cart?.orderInfo.pickupTime">
            {{'Choose Time - Date' | translate}}
          </span>
          <span *ngIf="cart?.orderInfo.pickupTime">
            {{cart?.orderInfo.pickupTime | iknDate: 'day'}} {{cart?.orderInfo.pickupTime | iknDate: 'dm'}}
            {{cart?.orderInfo.pickupTime | iknDate: 'tf'}}<span *ngIf='cart?.orderInfo.pickupTimeTo'> -
              {{cart?.orderInfo.pickupTimeTo | iknDate: 'tf'}}</span>
          </span>
        </div>
        <div
          *ngIf="cart.serviceType_ex && cart.serviceType_ex != 'soonest_delivery' && cart.serviceType_ex != 'pickup'">
          <span><i class="far fa-angle-right"></i></span>
        </div>
      </div>
    </div>
    <hr />
    <div class="search">
      <button type="submit" class="search_button">
        <i class="fa fa-search"></i>
      </button>
      <input type="text" class="search_term font-extraBold" placeholder="{{
              (availableItems  ?
                'SEARCH_PLACE_HOLDER' :  'Search') | translate: {count: availableItems}
              }}" [(ngModel)]="searchText">
    </div>
  </div>
  <div iknLoader [isActive]='isLoading || isLoadingMenu' class="loader-container mt-4 pt-4"></div>
  <div class="menu-block w-100" [@fadeInOnEnter]
    *ngIf="!searchText && !searchText?.length && !isLoading && !isLoadingMenu">
    <app-base-category-card [category]="menu.cateringCategory" *ngIf="menu.cateringItems?.length" (click)='cateringIniated = true'>
    <app-base-category-card [category]="mostSellingCat" *ngIf="showMostSoldItems"></app-base-category-card>
    <app-base-category-card [category]="recentlyAddedCat" *ngIf="showRecentlyAddedItems"></app-base-category-card>
    </app-base-category-card>
    <div *ngFor="let cat of menu?.categories | category:searchText" class='w-100'>
      <app-base-category-card [category]="cat"></app-base-category-card>
    </div>

  </div>

  <div class="menu-block" [@fadeInOnEnter] [@fadeOutOnLeave]
    *ngIf="searchText && searchText?.length && !isLoadingMenu && !isLoading">
    <div>

      <div *ngFor="let item of menu?.allItems | filter: searchText  | slice:0:100 ">
        <app-menu-item-card [item]="item" [extraChargeAlert]="extraCatAlertSelector"></app-menu-item-card>
      </div>
    </div>

  </div>
  <!-- <div class="menu-block" [@fadeInOnEnter] [@fadeOutOnLeave]
    *ngIf="!isLoadingMenu && !isLoading && cart?.serviceType_ex == 'catering'">
    <div>

      <div *ngFor="let item of cateringItems | filter: searchText  | slice:0:100 ">
        <app-menu-item-card [item]="item" [extraChargeAlert]="extraCatAlertSelector"></app-menu-item-card>
      </div>
    </div>

  </div> -->


</div>



<div *ngIf="iosLink || androidLink"
  style="display:flex;justify-content: center;align-items: center;margin-bottom: 5px;">
  <div *ngIf="iosLink" style="text-align: center;">
    <a [href]="iosLink" target="_blank"><img src="../../assets/images/app-store-badge.png"
        style="width:250px;padding-left:8px" /></a>
  </div>
  <div *ngIf="androidLink" style="text-align: center;">
    <a [href]="androidLink" target="_blank"><img src="../../assets/images/google-play-badge.png"
        style="width:300px" /></a>
  </div>
</div>
<div *ngIf="showTermsLink" class='t-medium f-boldes text-center py-2'>
  <a [routerLink]="['/terms']">{{'Service Terms and Conditions' | translate}}</a>
</div>

<app-extra-category-alert class="pull-heading-right"></app-extra-category-alert>
<app-menu-alert class="pull-heading-right"></app-menu-alert>

<app-footer></app-footer>