import { Component, OnInit, ViewEncapsulation, Input, AfterViewInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { CartService } from '../cart.service';
import { UtilsService } from '../utils.service';
import { Cart } from '../cart';
import { ApiService } from '../api.service';
import * as _ from 'lodash'
import { TranslateService } from '@ngx-translate/core';
import { MakeReservationRes, TruckBranch, TruckReservationEditDto, TruckSBOptionsWithDetails } from '../models';
import * as moment from 'moment';
import { HOUR } from 'ngx-bootstrap/chronos/units/constants';
declare var QRCode:any;
@Component({
  selector: 'app-make-reservation',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './make-reservation.component.html',
  styleUrls: ['./make-reservation.component.css']
})
export class MakeReservationComponent implements OnInit, AfterViewInit {
 
  cart:Cart;
  MakeReservation: boolean = false;
  isLoading:boolean = true;
  sbOptions: TruckSBOptionsWithDetails;
  branches: TruckBranch[];
  reservationInfo: TruckReservationEditDto = {};
  reservationResult: MakeReservationRes;
  date: Date = new Date();
  isSaving = false;
  isSaved = false;
  public memberInfo =  {
    civilId: '',
    fileNumber: '',
    membershipNo: ''
  }
  nextCallback:()=>void
  constructor(public modal:NgxSmartModalService,
    private trans: TranslateService,
    private cartService: CartService,private utils:UtilsService, private api : ApiService) { 

  }
  
  
  ngOnInit() {
    this.cart = this.cartService.getCart();
    
    let that = this;
    this.api.viewSBOptionsDetails().subscribe(d=>{
      this.sbOptions = d;
      if(d.hasMultipleBranches){
        this.api.viewTruckBranches(null).subscribe(b=>{
          this.branches = b && b.items
          this.isLoading = false;
        }, err=>{
          this.isLoading = false;
        });
      }else{
        this.isLoading = false;
      }
    }, err=>{
      this.isLoading = false;
    });    
  }

  ngAfterViewInit(){
    // this.modal.getModal('mMakeReservation').onDismiss.subscribe(d=>{
    //   this.reservationResult = null;
    //   this.isSaved = false;
    // })
    this.modal.getModal('mMakeReservation').onAnyCloseEvent.subscribe(d=>{
      setTimeout(() => {
        this.reservationResult = null;
        this.isSaved = false;
        this.reservationInfo = {};  
        $('#qrcode').html('');
      }, 1000);
    })
  }
  onSave(){
    if(this.reservationResult)
    {
      $('#lnk_getDirections').trigger('click');
      this.modal.getModal('mMakeReservation').close();
      return;
    }

    if(!this.reservationInfo.name)
    {
      this.utils.notify('warning',this.trans.instant('Name is required'), ' ');
      return;
    }
    if(!this.reservationInfo.civilId || this.reservationInfo.civilId.length < 14)
    {
      this.utils.notify('warning',this.trans.instant('Civil id is required and it length must be exactly 14 characters'), ' ');
      return;
    }
    if(this.sbOptions.hasMultipleBranches && !this.reservationInfo.branchId)
    {
      this.utils.notify('warning',this.trans.instant('Please select branch'), ' ');
      return;
    }
    

    this.isSaving = true;
    this.reservationInfo.reservationDate = moment(this.date).toDate().getTime();
    this.api.makeReservation(this.reservationInfo).subscribe(d=>{
      this.reservationResult = d;
        var qrcode = new QRCode(document.getElementById("qrcode"), {
          width : 100,
          height : 100
        });
        qrcode.makeCode(d.reservationNumber);

      this.isSaved = true;
      this.isSaving = false;
    }, err=>{
      this.isSaving = false;
      err = err && err.error;
      if(err && err.code == '7025')
      {
        this.utils.error(this.trans.instant('r_err_7025', {date: moment(err.errorDetail.nearestAvailable).format('DD/MM/YYYY hh:mm A')}))
        this.date = new Date(err.errorDetail.nearestAvailable);
        
        return;
      }
    });
  }

  open(){
    this.date = moment().add(1,'hour').toDate();
    console.log(this.date);
    this.modal.getModal('mMakeReservation').open();
  }

}
