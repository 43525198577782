import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../utils.service';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from '../cart.service';
import { CartItem, CartExtraItem, MenuExtraItem } from '../models';
import * as moment from 'moment';
import * as _ from 'lodash'
import { OrderSuccessPopupComponent } from '../order-success-popup/order-success-popup.component';
import { NotifyService } from '../notify.service';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css']
})
export class OrderHistoryComponent implements OnInit {

  lstOrders: any;
  isLoading: boolean = false;
  orderNo: string;
  orderId: string;
  successRes:boolean;
  notFoundRes:boolean;
  deliveryState:any;
  isCanceled:boolean;
  cancelMessage:string;
  @ViewChild(OrderSuccessPopupComponent) orderSuccessSelector: OrderSuccessPopupComponent;

  constructor(public utils: UtilsService, private api: ApiService
    ,private router:Router, private srvTrans : TranslateService
    , private route:ActivatedRoute
    ,private srvCart: CartService,
    private notify: NotifyService ) { }

  ngOnInit() {
    
    if(!this.api.isLoggedIn)
    {
      
      this.router.navigate(['/login']);
      return;
    } 
    this.utils.fbTrack('MyOrders');
    
   

    this.route.paramMap
    .subscribe(params => {
      this.orderNo = params.get('orderNo') || "";
      this.orderId = params.get('id') || "";
      let type = params.get('ot') || "";
      if(this.orderId)
        setTimeout(() => this.orderSuccessSelector.showOrderSuccessPopup(() => { this.getMyOrders(); }, this.orderId, type));
      else
        this.getMyOrders();
    });
  }

  getMyOrders(){
    if(this.isLoading)
    return; //already loading something
    this.isLoading = true;
    this.api.getMyOrders(this.orderNo).subscribe(res => {
     
      this.lstOrders = res &&res.items;
      this.isLoading = false;
    },function(error){
      this.isLoading = false;
    }); 
  }

  trackOrder() {
    this.successRes = false;
    this.notFoundRes = false;

    if(this.isLoading)
      return; //already loading something
    this.isLoading = true;
    this.api.getDeliveryStatus(this.orderNo).subscribe(res=>{
      this.isLoading = false;
      this.deliveryState = res.deliveryState;
      console.log(this.deliveryState);
      let canceledStatus = ['Canceled', 'PaymentRejected', 'DeliveryIssue'];
      let status = res.deliveryState.order.status;
      this.isCanceled = canceledStatus.includes(status);
      this.cancelMessage = status == 'PaymentRejected' ?
        'Payment for this order has been rejected' : (
          status == 'PendingPayment'  ? 'Order not payed' : 'Order has been canceled'
        );
      this.successRes = true;
    }, err=>{
      this.isLoading = false;
      this.notFoundRes = true;
     });
  }

  getTimeAgo(date){
    return moment(date).fromNow();
  }

  reOrder(order){
    this.isLoading = true;
    this.api.viewOrder(order.id).subscribe(res => {
      this.isLoading = false;
      this.srvCart.getCart().cartItems = [];
      _.each(res.items, item=>{
        this.api.viewItem(item.item.id).subscribe(it => {
          this.srvCart.addItem(_.assignIn(new CartItem(),{
            $item: it.itemInfo,
            extras: _.map(item.extras, ei=>{
              let res =new CartExtraItem();
              let foundCat = _.find(it.extras, ec => ec.extraCategory.id == ei.extraCategory.id);
              if(foundCat){
               let foundExtra = _.find(foundCat.extraItems, ex => ex.id == ei.extraItem.id);
               ei.catId = ei.extraCategory.id;
               ei.id = ei.extraItem.id;
               res.$extraItem = _.assignIn(foundExtra, ei);
              }
              return res;
            }),
            itemId: item.item.id,
            unitPrice: item.unitPrice,
            qty: item.qty,
            notes: item.notes,
          }));
        });
        this.router.navigate(['/cart']);
      })
    });
  }
  cancelOrder(orderId){
    this.notify.sweet_confirm('Order will be canceled', undefined,  ()=> {
      this.isLoading = true;
      this.api.cancelOrder(orderId).subscribe( ()=> {
        this.isLoading = false;
        this.notify.sweet_success('Order is canceled successfully');
        this.getMyOrders();
      },  (error) => {
        this.isLoading = false;
        this.notify.sweet_error(error);
      });
    });
  }
  validUntil(validUntil){
    return new Date(validUntil) > new Date();
  }
}
