import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApiResult, Area, governate, LocalizedField, TruckInfo } from '../models';
import { ApiService } from '../api.service';
import { UtilsService } from '../utils.service';
import { CartService } from '../cart.service';
import { Cart } from '../cart';
import * as _ from 'lodash';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-login-part',
  templateUrl: './login-part.component.html',
  styleUrls: ['./login-part.component.css']
})
export class LoginPartComponent implements OnInit {
  @Input()
  returnTo: string[] = ['/'];
  truck: TruckInfo;
  cart: Cart;
  username: string;
  password: string;
  regUsername: string;
  regPassword: string;
  
  displayName: string;
  loginForm: FormGroup;
  quickRegisterForm: FormGroup;
  quickRegister: boolean = false;
  isLoading: boolean = false;
  submitted: boolean = false;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Kuwait, CountryISO.Qatar];
  phoneNumber: any;
  selectedCountryCode: string;
  @ViewChild('frmQuickRegister') frmQuickRegister: NgForm;
  @ViewChild('frmLogin') frmLogin: NgForm;

  constructor(private api: ApiService, private srvCart: CartService,
    private router: Router,
    public utils: UtilsService, formBuilder: FormBuilder) {
    this.loginForm = formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],

    });
    this.quickRegisterForm = formBuilder.group({
      quickRegister: [''],
      displayName: ['', Validators.required],
      phoneNumber: ['', Validators.required],

    });
    this.cart = this.srvCart.getCart();
    this.selectedCountryCode = this.cart.orderInfo.pickupAddress.countryCode || (this.cart.$truck && this.cart.$truck.defaultCountry && this.cart.$truck.defaultCountry.code2) || 'kw';
  }

  ngOnInit() {
  }

  get fl() { return this.loginForm.controls; }
  get fq() { return this.quickRegisterForm.controls; }

  login() {
    if (this.isLoading)
      return;
    if (this.quickRegister)
      this.frmQuickRegister.ngSubmit.emit();
    this.frmLogin.ngSubmit.emit();
    this.submitted = true;

    if (this.frmLogin.invalid || (this.quickRegister && this.frmQuickRegister.invalid)) {
      this.utils.scrollToError();
      return false;
    }

    this.isLoading = true;
    if (this.quickRegister) {
      this.api.register({
        username: this.username,
        password: this.password,
        profile: {
          username: this.username,
          email: this.username,
          password: this.password,
          displayName: this.displayName,
          phoneNumber: this.phoneNumber.internationalNumber
        }
      }).subscribe(d => {
        this.cart.orderInfo.contactName = this.displayName;
        this.cart.orderInfo.contactPhone = this.phoneNumber.internationalNumber;
        this.srvCart.saveCart();
        this.api.authUser(d);
        if (this.returnTo && this.returnTo.length)
          this.router.navigate(this.returnTo);
        else
          this.utils.goBack();
      });
      return;
    }
    //login
    this.api.login({
      userName: this.username,
      password: this.password
    }).subscribe(d => {
      this.api.authUser(d);
      if (this.returnTo && this.returnTo.length)
        this.router.navigate(this.returnTo);
      else
        this.utils.goBack();
    }, d => {
      this.utils.error(d);
      this.isLoading = false;
    })
  }
}
