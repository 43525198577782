import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'iknMinutes',
  pure:false,
})
export class iknMinutesPipe implements PipeTransform {
 
  constructor(@Inject(TranslateService) private srvTrans:TranslateService) {
    
  }
  transform(time: any): any {
    if(!time)
      return '';
    let digits = (time as string).match(/\d+/g);
    let postfix =(time as string).match(/[mhdMHD]+/g);
    if(!postfix || !postfix.length)
      postfix = ['m'];
    if(digits.length != postfix.length)
      return '';
    let totalMinutes = 0;
    for(let i=0; i<digits.length; i++) {
      postfix[i] = _.lowerCase(postfix[i]);
      if(postfix[i] == 'h')
        totalMinutes += Number(digits[i]) * 60;
      else if(postfix[i] == 'd')
        totalMinutes += Number(digits[i]) * 60 * 24;
      else
        totalMinutes += Number(digits[i]);
    }
      time = totalMinutes;
      let years = time / 365 / 24 / 60;
      let months = time / 30 / 24 / 60;
      let days = time / 24 / 60;

      let hours = time / 60 % 24;
      let minutes = time % 60;

      let yearStr = (years >= 1 ? `${years.toFixed(0)} ${this.srvTrans.instant('Years')} ` : '');
      let monthsStr =  (months >= 1 ? `${months.toFixed(0)} ${this.srvTrans.instant('Months')} ` : '');
      let daysStr =  (days >= 1 ? `${days.toFixed(0)} ${this.srvTrans.instant('Days')} ` : '');
      let hoursStr = (hours >= 1 ? `${Math.floor(hours)} ${this.srvTrans.instant('Hours')} ` : '');
      let minutesStr =  (minutes >= 1 ? `${minutes.toFixed(0)} ${this.srvTrans.instant('Minutes')} ` : '');
      console.log(time,yearStr, monthsStr, daysStr);

      return _.join(_.filter([yearStr,monthsStr,daysStr,hoursStr, minutesStr], d=>d), this.srvTrans.instant('and'));
  }

}
