<div class="ikn-modal order-success">
    <ngx-smart-modal #mOrderSuccess identifier="mOrderSuccess" (onDismiss)="onDismiss()">
        <div class="font-bold">
            <div iknLoader [isActive]='isLoading' class="loader-container"></div>
            <div class="checkout-block" *ngIf='order?.serviceType != "catering"'>
                <div class="form-group" *ngIf="!isLoading">
                    <div class="margin-top-10"></div>
                    <div class="text-center"
                        *ngIf="order?.status != 'PendingPayment' && order?.status != 'PaymentRejected'">
                        <img src="../../assets/images/tick-icon.png" alt="Order Submitted Successfully"
                            class="success-img" />
                        <!-- <img src="../../assets/images/OrderSuccess.svg" alt="Order Submitted Successfully" class="success-img"/> -->
                        <div class="margin-top-10"></div>
                        <h6 class="no-margin font-extraBold">
                            {{'Order submitted successfully' | translate}}
                        </h6>
                        <div class="margin-top-10"></div>
                        <h6 class="text-success"> {{ order?.submittedAt |iknDate:'df'}} |
                            {{ order?.submittedAt |iknDate:'tf'}}</h6>
                        <div class="margin-top-10"></div>
                        <h3>{{order?.pickupLocation.contactName || order?.customerName}}</h3>
                        <h5 class="reversed-phone">{{order?.pickupLocation.contactPhone || order?.customerPhone}}</h5>
                        <!-- <div class="margin-top-10"></div> -->
                        <div class="text-left px-4">
                            <p class="order-placed font-bold">
                                {{'Order ID' | translate}}: <span
                                    class="f-t-24 m-l-20 font-extraBold text-success">{{order?.orderNo}}</span>
                            </p>
                            <p class="order-placed font-bold margin-top-10" *ngIf="order?.serviceType != 'catering'">
                                <span class='m-r-20'>{{(order?.serviceType == 'delivery' ? 'Delivery Date' : 'Pickup
                                    Date') | translate}}:
                                </span>
                                <span class="m-l-20 font-extraBold text-success">
                                    <!-- {{'Around' | translate}}  -->
                                    {{(order?.pickupTime || order?.expectedDeliveryTime) |iknDate:'df'}}
                                </span>
                            </p>
                            <p class="order-placed font-bold margin-top-10"
                                *ngIf="!dateOnly && order?.serviceType_ex != 'catering'">
                                <span class="pull-left">{{(order?.serviceType == 'delivery' ? 'Expected Delivery Time' :
                                    'Expected Pickup Time') | translate}}:</span>
                                <span class="font-extraBold text-success pull-left">
                                    <span class="margin-to-time"
                                        *ngIf="order?.pickupTimeTo || order?.expectedDeliveryTimeTo">{{'From' |
                                        translate }}</span>
                                    &nbsp;{{(order?.pickupTime || order?.expectedDeliveryTime) |iknDate:'tf'}}
                                    <span *ngIf="order?.pickupTimeTo || order?.expectedDeliveryTimeTo">
                                        {{'to' | translate}}
                                        {{(order?.pickupTimeTo || order?.expectedDeliveryTimeTo) |iknDate:'tf'}}
                                    </span>
                                </span>
                            </p>
                            <div class="clearfix"></div>
                            <p class="order-placed font-bold m-t-16" *ngIf="order?.serviceType == 'pickup'">{{"Pickup
                                Location" | translate}}:
                                <span class="m-l-20 font-extraBold">
                                    {{order?.branch?.name | iknLocalized}}
                                    <span *ngIf='!order?.branch'>{{'Main Branch' | translate}}</span>
                                </span>
                                <span>
                                    <a target="blank"
                                        [href]="'https://www.google.com/maps/search/?api=1&query=' + location?.lat + ',' + location?.lng">
                                        <span class='text-white'>&nbsp;{{address | iknLocalized}}</span>
                                        <i class="text-success fa fa-map-marker"></i></a>
                                </span>
                            </p>
                        </div>

                        <!-- <div style="height: 40px"></div> -->
                        <div class="margin-top-10"></div>
                        <img src="../../assets/images/screenshot.png" alt="Order Submitted Successfully"
                            class="screen-shot" />
                        <div class="margin-top-10"></div>
                        <h1 class="no-margin order-screen font-bold">{{'Take a screenshot to track your order' |
                            translate}}
                        </h1>
                    </div>
                    <div class="text-center"
                        *ngIf="order?.status == 'PendingPayment'  || order?.status == 'PaymentRejected'">
                        <img src="../../assets/images/warning.png" alt="Order Not Completed" class="success-img" />
                        <div class="margin-top-10"></div>
                        <h1 class="no-margin font-bold">{{'Your Order is not completed' | translate}}</h1>
                        <h2 class="font-weight-500 no-margin f-s-22">
                            <h3 class="no-margin font-weight-400 f-s-18 font-bold">{{'Your Order Number is' |
                                translate}}
                                {{order?.orderNo}}</h3>
                        </h2>
                    </div>
                </div>
                <div class="m-t-20"></div>
                <div class="form-group btn-container text-center"
                    *ngIf="order?.status != 'PendingPayment' && order?.status != 'PaymentRejected'">
                    <button class="btn btn-continue d-inline text-center" (click)="onDismiss()">
                        <!-- [routerLink]="['/track-order']" [queryParams]="{ orderNo: order?.orderNo }" -->
                        {{'Track Your Order' | translate}}
                    </button>
                </div>
            </div>
            <div class="checkout-block t-normal" *ngIf='order?.serviceType == "catering"'>
                <div class="text-center py-4"
                    *ngIf="order?.status != 'PaymentRejected'">
                    <img src="../../assets/images/tick-icon.png" alt="Order Submitted Successfully"
                        class="success-img" />
                    <!-- <img src="../../assets/images/OrderSuccess.svg" alt="Order Submitted Successfully" class="success-img"/> -->
                    <div class='py-4 d-flex justify-content-center align-items-center text-center'>
                        <div>{{'Order Number' | translate}}:</div>
                        <div class='t-large f-boldest mx-1'>{{order?.orderNo}}</div>
                    </div>
                    <div class='py-2 text-center'>
                        <div class='d-flex justify-content-center align-items-center'>
                            <div>
                                {{'Order Date' | translate}}: {{order?.submittedAt | iknDate:'df'}}
                            </div>
                            <div class='mx-4'></div>
                            <div>
                                {{'Order Time' | translate}}: {{order?.submittedAt | iknDate:'tf'}}
                            </div>
                        </div>
                    </div>
                    <div class='py-2'></div>
                    <div class='py-4' class='text-center'>
                        {{'Order Status' | translate}}: <span class='text-success'>{{order?.status | translate}}</span>
                    </div>
                    <div class='py-4 text-center' *ngIf='order?.status == "PendingVendorConfirm"'>
                        {{'PENDING_REVIEW_TEXT' | translate}}
                    </div>
                    <!-- <div style="height: 40px"></div> -->
                    <div class="margin-top-10"></div>
                    <h1 class="no-margin order-screen font-bold">{{'Take a screenshot to track your order' | translate}}
                    </h1>
                    <div class="margin-top-10"></div>
                    <img src="../../assets/images/screenshot.png" alt="Order Submitted Successfully"
                        class="screen-shot" />
                </div>
                <div class="m-t-20"></div>
                <div class="form-group btn-container text-center"
                    *ngIf="order?.status != 'PaymentRejected'">
                    <button class="btn btn-continue d-inline text-center" (click)="onDismiss()">
                        <!-- [routerLink]="['/track-order']" [queryParams]="{ orderNo: order?.orderNo }" -->
                        {{'Track Your Order' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </ngx-smart-modal>
</div>