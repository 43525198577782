<app-top-header class="top-bar-container" bgStyle="solid" screenTitle="Track order"></app-top-header>
<div>
    <form [formGroup]="submitForm" (ngSubmit)="submitTrackOrder()">
        <div class="page-heading">
            <div class="heading-l">
                <div class="title"><a class="back color-primary" (click)="onBack()"><i
                            class="fa fa-chevron-left"></i></a></div>
            </div>
            <div class="heading-l heading-c">
                <div class="title font-extraBold">{{'Track Order' | translate}}</div>
            </div>
            <div class="heading-r"></div>
        </div>
        <div iknLoader [isActive]='isLoading' class="loader-container"></div>
        <div class="page-content">
            <div class="checkout-block">
                <div class="form-group">
                    <div class="form-group-body">
                        <div class="input-group">
                            <div class="row">
                                <div class="d-content">
                                    <div class="search">
                                        <!-- <i class="im im-deladdress "></i> -->
                                        <input type="text" required class="search_term t-center font-extraBold"
                                            placeholder="{{'Order Number - Phone Number' | translate}}"
                                            [(ngModel)]="orderNo" formControlName="orderNo">
                                    </div>
                                    <div *ngIf="submitted && f.orderNo.errors" class="invalid-feedback">
                                        <div *ngIf="f.orderNo.errors.required">
                                            {{'Order Number is required'|translate}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="successRes && !isCanceled" class="p-t-1">
                            <div class="pull-left font-extraBold margin-top-10">
                                <div class="order-id">#{{deliveryState.order.orderNo}}</div>
                                <div class="desc" *ngIf="deliveryState.order?.branch?.name != null">
                                    <div>
                                        <div class="inline-block"><strong>{{'Branch' | translate}}: </strong></div>
                                        <div class="inline-block"> {{deliveryState.order?.branch?.name | iknLocalized}}
                                        </div>
                                    </div>
                                    <div>
                                        <div class="inline-block">
                                            <strong>{{'Pickup Location' | translate}}: </strong>
                                        </div>
                                        <div class="inline-block"> <a target="blank"
                                                [href]="'https://www.google.com/maps/search/?api=1&query=' + deliveryState.order?.branch?.location?.location?.lat + ',' + deliveryState.order?.branch?.location?.location?.lng">
                                                {{deliveryState.order?.branch?.location?.address | iknLocalized}}

                                                <i class="text-success fa fa-map-marker"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="clear: both;"></div>
                            <div class="margin-top-10"></div>
                            <div class="row align-items-center font-bold">
                                <div class="main-track-circle">
                                    <div class="inner-track-circle" *ngIf="isPassedStatus('Accepted')">
                                        <div class="solid-track-circle bg-primary"></div>
                                    </div>
                                </div>
                                <div class="column w-100">
                                    <div class="card-track w-100">
                                        <div class="card-track-status">
                                            <div class="j-start">
                                                {{'Accepted'|translate}}
                                            </div>
                                            <div class="j-end" *ngIf="isPassedStatus('Accepted')">
                                                {{deliveryState.acceptedAt | iknDate:'tf'}}
                                            </div>
                                        </div>
                                        <div class="card-track-data" *ngIf="isPassedStatus('Accepted')">
                                            {{deliveryState.acceptedAt | iknDate:'dmy'}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="margin-top-10"></div>
                            <div class="row align-items-center font-bold">
                                <div class="main-track-circle">
                                    <div class="inner-track-circle" *ngIf="isPassedStatus('Preparing')">
                                        <div class="solid-track-circle bg-primary"></div>
                                    </div>
                                </div>
                                <div class="column w-100">
                                    <div class="card-track w-100">
                                        <div class="card-track-status">
                                            <div class="j-start">
                                                {{'Processing'|translate}}
                                            </div>
                                            <div class="j-end" *ngIf="deliveryState.order.processedAt">
                                                {{deliveryState.order.processedAt | iknDate:'tf'}}
                                            </div>
                                        </div>
                                        <div class="card-track-data" *ngIf="deliveryState.order.processedAt">
                                            {{deliveryState.order.processedAt| iknDate:'dmy'}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="margin-top-10"></div>
                            <div class="row align-items-center font-bold">
                                <div class="main-track-circle">
                                    <div class="inner-track-circle" *ngIf="isPassedStatus('Prepared')">
                                        <div class="solid-track-circle bg-primary"></div>
                                    </div>
                                </div>
                                <div class="column w-100">
                                    <div class="card-track w-100">
                                        <div class="card-track-status">
                                            <div class="j-start">
                                                {{'Ready to pickup'|translate}}
                                            </div>
                                            <div class="j-end" *ngIf="deliveryState.order.processingEndedAt">
                                                {{deliveryState.order.processingEndedAt | iknDate:'tf'}}
                                            </div>
                                        </div>
                                        <div class="card-track-data" *ngIf="deliveryState.order.processingEndedAt">
                                            {{deliveryState.order.processingEndedAt| iknDate:'dmy'}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="margin-top-10" *ngIf="deliveryState.order.serviceType == 'delivery'"></div>
                            <div class="row align-items-center font-bold">
                                <div class="main-track-circle">
                                    <div class="inner-track-circle" *ngIf="isPassedStatus('PickedUp')">
                                        <div class="solid-track-circle bg-primary"></div>
                                    </div>
                                </div>
                                <div class="column w-100" *ngIf="deliveryState.order.serviceType == 'delivery'">
                                    <div class="card-track w-100">
                                        <div class="card-track-status">
                                            <div class="j-start">
                                                {{'On the way' | translate}}
                                            </div>
                                            <div class="j-end" *ngIf="deliveryState.pickedupAt">
                                                {{deliveryState.pickedupAt | iknDate:'tf'}}
                                            </div>
                                        </div>
                                        <div class="card-driver" *ngIf="isPassedStatus('PickedUp')">
                                            <div class="card-driver-inner">
                                                <div>
                                                    <span
                                                        class="driver-name f-w-b">{{'Driver Name' | translate}}</span><span
                                                        class="driver-name f-w-b">: </span><span
                                                        class="driver-color f-w-b">{{deliveryState.order?.driverName | iknLocalized}}</span>
                                                </div>
                                                <div>
                                                    <span
                                                        class="driver-name f-w-b">{{'Contact' | translate}}</span><span
                                                        class="driver-name f-w-b">: </span><span
                                                        class="driver-color f-w-b contact-border-green"
                                                        *ngIf="deliveryState.order?.driverPhone != null">
                                                        <a
                                                            [href]="'tel://' + deliveryState.order?.driverPhone">{{deliveryState.order?.driverPhone}}</a></span>
                                                </div>
                                            </div>
                                            <div class="card-driver-inner t-center" (click)="mapClick()"
                                                *ngIf="!isPassedStatus('PendingPaymentFromDriver')">
                                                <div class="no-font">
                                                    <img src="../../assets/images/markers/map-marker.png" alt="track"
                                                        class="map-marker">
                                                </div>
                                                <div class="p-b-5">
                                                    <span class="track-map f-w-b">{{'Track on Map' | translate}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="margin-top-10"></div>
                            <div class="row align-items-center font-bold">
                                <div class="main-track-circle">
                                    <div class="inner-track-circle" *ngIf="isPassedStatus('PendingPaymentFromDriver')">
                                        <div class="solid-track-circle bg-primary"></div>
                                    </div>
                                </div>
                                <div class="column w-100">
                                    <div class="card-track w-100">
                                        <div class="card-track-status">
                                            <div class="j-start">
                                                {{(deliveryState.order.serviceType == 'delivery' ? 'Delivered' : 'PickedUp') | translate}}
                                            </div>
                                            <div class="j-end"
                                                *ngIf="deliveryState.deliveredAt || deliveryState.completedAt">
                                                {{ (deliveryState.deliveredAt || deliveryState.completedAt) | iknDate:'tf'}}
                                            </div>
                                        </div>
                                        <div class="card-track-data" *ngIf="deliveryState.deliveredAt">
                                            {{deliveryState.deliveredAt| iknDate:'dmy'}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="margin-top-10"></div>
                        </div>
                        <div class="invalid-feedback text-center font-bold" *ngIf="successRes && isCanceled">
                            <h2 class="font-weight-500">{{cancelMessage | translate}}</h2>
                        </div>
                        <div class="invalid-feedback text-center font-bold" *ngIf="submitted && notFoundRes">
                            <h2 class="font-weight-500">{{'Order not found' | translate}}</h2>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="page-btn font-bold">
            <a class="btn btn-primary" (click)="submitTrackOrder()">
                {{'Track Order' | translate}}
            </a>
            <app-footer></app-footer>
        </div>
    </form>
</div>
<app-order-success-popup (trackOrder)="this.orderNo = $event;"></app-order-success-popup>