<div class="cat-big-card" (click)="onCategoryClick(cat)">
  <div class='d-flex flex-column c-pointer'>
    <!-- <img class="cat-img  border rounded" [src]="cat?.categoryInfo?.mainPic || 'assets/images/logo.png'"> -->
    <div class="cat-img border rounded"
      [attr.style]="'background-image:url(' + (cat?.categoryInfo?.mainPic || cart?.$truck?.logoPic || cart?.$truck?.mainPic || 'assets/images/logo.png') + ')'">
    </div>
    <div class="cat-title">
      <h3>{{cat.categoryInfo.title | iknLocalized}}</h3>
    </div>
  </div>

  <div class='position-fixed' style='z-index:11;bottom:0;left:0;right:0;top:0;background-color: rgba(0,0,0,0.4);'
    *ngIf='cateringIniated'>
    <div class='position-absolute' style='left:0;right:0;bottom:10px;z-index:12' *ngIf='cateringIniated'
      [@slideInUpOnEnter] [@slideOutDownOnLeave]>
      <div class='page-sub-container' *ngIf='cateringIniated'>
        <div class='p-2'>
          <div
            class='d-flex flex-column align-content-center jsutify-content-center border shadow rounded p-2 '
            style='background-color: white;'>
            <div class='d-flex jsutify-content-between align-items-center'>
              <div class='py-1 f-bolder t-normal flex-grow-1 color-primary text-center'>{{'Select Catering Area And
                Time'
                | translate}}</div>
              <!-- <div class='color-primary cursor-pointer' (click)='cateringIniated=!cateringIniated'>
              <u>{{'Cancel' | translate}}</u>
            </div> -->
            </div>
            <div class='border-bottom'></div>
            <div class='p-2'>
              {{'Where?' | translate}}
            </div>
            <div class='p-2 color-grey' routerLink="catering-area">
              <span *ngIf='!cart.orderInfo.pickupAddress.areaId'>{{'Select Area' | translate}} </span>
              <span *ngIf='cart.orderInfo.pickupAddress.areaId'>{{cart.orderInfo.pickupAddress.areaName | iknLocalized}}
              </span>
            </div>
            <div class='border-bottom'></div>
            <div class='p-2'>
              {{'When?' | translate}}
            </div>
            <div class='p-2 color-grey' routerLink="catering-time-date">

              <span *ngIf='!cateringTime'>{{'Select Time And Date' | translate}}</span>
              <span *ngIf='cateringTime'>{{cateringTime | iknDate:'dtf'}}</span>
              <span *ngIf='cateringTimeTo'> - {{cateringTimeTo | iknDate:'tf'}}</span>

            </div>
            <div class='border-bottom'></div>
            <div class='pt-2'>
              <div class='d-flex jsutify-content-center text-center align-items-center p-2 color-white bg-primary'
                (click)='continueCatering()'>
                <div class='text-center w-100'>
                  {{'Continue' | translate}}
                </div>
              </div>
            </div>
            <div class='py-2'>
              <div class='d-flex jsutify-content-center align-items-center p-2  border bc-primary color-primary'
                (click)='skipCatering()'>
                <div class='text-center w-100'>
                  {{'Skip' | translate}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>