import { AfterViewInit, Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ApiResult, Area, governate, LocalizedField, TruckInfo } from '../models';
import { ApiService } from '../api.service';
import { UtilsService } from '../utils.service';
import { CartService } from '../cart.service';
import { Cart } from '../cart';
import * as _ from 'lodash';
import { AddressPartComponent } from '../address-part/addres-part.component';
import { Router } from '@angular/router';
import { slideInUpOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-pre-checkout',
  templateUrl: './pre-checkout.component.html',
  styleUrls: ['./pre-checkout.component.css'],
  animations: [slideInUpOnEnterAnimation({ delay: 1000 })]
})
export class PreCheckoutComponent implements OnInit, AfterViewInit {
  truck: TruckInfo;
  continueAs: string = 'guest';
  cart: Cart;
  selectedService: string
  services = [];
  isLoading: boolean = false;
  isShippingMode: boolean = false;
  areas = [];
  governates = [];
  selectedArea: Area;
  governate: governate;
  searchText: string;
  _oldArea: number
  results = [];
  @ViewChild('address') cmpAddress: AddressPartComponent;

  constructor(private api: ApiService, private srvCart: CartService, private router: Router, public utils: UtilsService) {
    this.cart = srvCart.getCart();
    this.isLoading = true;
    if (this.api.isLoggedIn) {
      let route = [];
      if (this.cart.orderInfo.pickupAddress.selectedAddressId) {
        console.log(this.cart.orderInfo.pickupAddress);
        route = ['/checkout']

      } else {
        route  = ['select-address'];
       }
      this.router.navigate(route, {
        replaceUrl: true,
        skipLocationChange: true
      });
      return;
    }
    if (this.cart.serviceType_ex == 'catering') {
      this.continueAs = 'login'
    }
    if(this.cart.serviceType_ex == 'pickup'){
      this.router.navigate(['/checkout'], {skipLocationChange:true,replaceUrl:true });
      return;
    }
    if(this.cart.serviceType_ex == 'soonest_delivery'){
      this.api.getDeliveryTimes(new Date(), 'delivery', this.api._orderFromCart(this.cart).cartItems).subscribe(t => {
        this.cart.orderInfo.pickupTime = t.asSoonAsFrom;
        if(t.asSoonAsFrom != t.asSoonAsTo)
          this.cart.orderInfo.pickupTimeTo = t.asSoonAsTo;
      });
    }
    api.viewTruck().subscribe(t => {
      this.isLoading = false;
      this.cart = srvCart.getCart();
      console.log('ishipping', t.truck.allowShippingOutsideCountry &&
      this.cart.orderInfo.pickupAddress.countryId != t.truck.defaultCountry.id);
      if(
        t.truck.allowShippingOutsideCountry &&
        this.cart.orderInfo.pickupAddress.countryId != t.truck.defaultCountry.id){
          this.isShippingMode = true;
        }
    })
  }

  ngOnInit() {
  }
  ngAfterViewInit() {

  }
  setService(service) {
    //TODO: As Soon As Possible Service
    if (service == 'soonest_delivery') {

      this.srvCart.setServiceType(service, 'soonest_delivery');
    } else {
      this.srvCart.setServiceType(service);
    }
    this.selectedService = service;
  }
  save() {
    //TODO:
    this.router.navigate(['/checkout']);
  }
  onGovernate(governate: governate) {
    this.governate = governate;
    governate.flag = !governate.flag

  }
  onAreaChanged(area:Area){
    this.srvCart.setArea(area);
    if(this.cart.serviceType_ex == 'soonest_delivery'){
      this.api.getDeliveryTimes(new Date(), 'delivery', this.api._orderFromCart(this.cart).cartItems).subscribe(t => {
        this.cart.orderInfo.pickupTime = t.asSoonAsFrom;
        if(t.asSoonAsFrom != t.asSoonAsTo)
          this.cart.orderInfo.pickupTimeTo = t.asSoonAsTo;
      });
    }
  }

}
