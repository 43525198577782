import { Component, Input, OnInit } from '@angular/core';
import { CategoryView } from '../models';
import * as _ from 'lodash'
import { Router } from '@angular/router';
import { NgIfContext } from '@angular/common';
import { CartService } from '../cart.service';
import { Cart } from '../cart';
import { slideInUpOnEnterAnimation, slideOutDownOnLeaveAnimation } from 'angular-animations';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../utils.service';
@Component({
  selector: 'app-base-category-card',
  templateUrl: './base-category-big-card.component.html',
  styleUrls: ['./base-category-big-card.component.css'],
  animations: [slideInUpOnEnterAnimation({ delay: 2 }), slideOutDownOnLeaveAnimation({ delay: 2 })]

})
export class BaseCategoryBigCardComponent implements OnInit {

  @Input('category') cat: CategoryView
  cart: Cart
  cateringIniated:boolean = false;
  cateringTime:number;
  cateringTimeTo:number;
  constructor(private router:Router, private srvCart : CartService, private trans:TranslateService, private utils:UtilsService) {
    this.cart = srvCart.getCart();
    this.cateringTime = srvCart.cateringTime;
    this.cateringTimeTo = srvCart.cateringTimeTo;
   }
   ngOnInit(){
    this.cateringIniated = this.srvCart.isCateringInitiated && this.cat.categoryInfo.id == -100;
    this.cateringTime = this.srvCart.cateringTime;
    this.cateringTimeTo = this.srvCart.cateringTimeTo

   }
   onCategoryClick(cat: any) {
    this.utils.CheckStateAndContinue(this.cart.$truck.lastKnowState, () => {
      if(cat.categoryInfo.id == -100) //catering
      {
        console.log(cat.categoryInfo.id);
        this.cateringIniated = true;
        this.srvCart.isCateringInitiated = true;
        this.cateringTime = null;
        this.cateringTimeTo = null;
         //this.srvCart.setServiceType('catering', 'catering');
 
         // this.router.navigate(['catering-area',{ returnTo: '/categories/' + cat.categoryInfo.id + '/items'}], {
         // });
          return;
      }
     if(cat.subCategories && cat.subCategories.length)  this.router.navigate(['/sub-category/'+ cat.id]);
     else  this.router.navigate(['/categories/' + cat.categoryInfo.id + '/items']);
    });
  }
  skipCatering(){
    this.srvCart.setServiceType('catering');
    this.cart.orderInfo.pickupTime = null;
    this.cart.orderInfo.pickupTimeTo = null;
    this.router.navigate(['catering-menu']);
    this.cateringIniated = false;
    this.srvCart.isCateringInitiated = false;
    this.srvCart.saveCart();
  }
  continueCatering(){
    if(!this.srvCart.cateringTime){
      this.utils.toaster.warning(this.trans.instant('Please select catering time'));
      return;
    }
    if(!this.cart.orderInfo.pickupAddress.areaId){
      this.utils.toaster.warning(this.trans.instant('Please select area'));
      return;
    }
    this.cateringIniated = false;
    this.srvCart.isCateringInitiated = false;
    this.cart.orderInfo.pickupTime = this.srvCart.cateringTime;
    this.cart.orderInfo.pickupTimeTo = this.srvCart.cateringTimeTo;

    this.srvCart.setServiceType('catering');
    this.srvCart.saveCart();
    this.router.navigate(['catering-menu']);
    //we should go to catering main screen
  }
}
