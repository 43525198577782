import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ViewCatalogGuard implements CanActivate {

  constructor(public api: ApiService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.api.viewSBOptionsDetails().subscribe(res => {
      if(res.simpleBrokerOptions && res.simpleBrokerOptions.viewCatalogOnly){
        this.router.navigate(['/']);
        return false;
      }else
        return true;
    });
    
    return true;
  }
  
}
